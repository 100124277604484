import { http } from "../http-common";

const getLogs = (entityName: string, accountId: string) => {
    return http.get<any>(`/accounts/${accountId}/logs`);
};

const AccountActivityService = {
    getLogs,
};

export default AccountActivityService;