import { Card, Col, Row, Button, Table, Select, Dropdown, Menu, DatePicker } from 'antd';
import { CalendarOutlined, WalletOutlined, DatabaseOutlined, DownOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import moment from 'moment';
import './Vallet.scss';
import EarningIcon from '../../assets/icons/earning.svg';
import NoteIcon from '../../assets/icons/note.svg';
import ValletIcon from '../../assets/icons/vallet2.svg';
import ProposalsList from '../../components/Vallet/ProposalsList';
import ProposalsCountCard from '../../components/Vallet/ProposalsCountCard';
import ContractCountCard from '../../components/Vallet/ContractCountCard';
import RenuewCard from '../../components/Vallet/RenuewCard';
import EarningsPerTransactionCard from '../../components/Vallet/EarningsPerTransactionCard';


const Vallet = () => {
  return (
    <div className='p-20px vallet ' style={{marginBottom:120}}>
      <Row gutter={[16, 16]}>
        <Col lg={6} md={12} xs={24}>
           <EarningsPerTransactionCard/>
        </Col>

        <Col lg={6} md={12} xs={24}>
          <ProposalsCountCard />
        </Col>

        <Col lg={6} md={24} xs={24}>
          <ContractCountCard />
        </Col>
        <Col lg={6} md={24} xs={24}>
          <RenuewCard/>
        </Col>
      </Row>

      <ProposalsList />
    </div>
  );
};

export default Vallet;