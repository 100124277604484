import axios, { CancelTokenSource } from 'axios';
import { clearStorage, openNotification } from './utils';

const locale = 'tr-TR';

export const withoutAccessHttp = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}`,
  headers: {
    'Accept': '*/*',
    'content-type': 'application/json',
  }
});

const cancelTokens: CancelTokenSource[] = [];

export const http = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}`,
  headers: {
    'Accept': '*/*',
    'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
    'content-type': 'application/json',
  }
});

// Request interceptor
http.interceptors.request.use(function (config: any) {
  const source = axios.CancelToken.source();
  config.cancelToken = source.token;
  cancelTokens.push(source);

  let token = localStorage.getItem('accessToken');
  if (token || config.url === '/login' || config.url === '/refresh' || config.url === '/authorize/token') {
    config.headers = {
      'authorization': `Bearer ${localStorage.getItem('accessToken')}`,
    };

    if (config.url === '/authorize/token') {
      config.headers = {
        'Content-Type': 'application/json',
        "accept-language": locale,
        'Access-Control-Allow-Origin': '*'
      }
    }

    return config;
  } else {
    window.location.href = '/login';
    throw new axios.Cancel('Operation canceled by the user.');
  }
}, function (error) {
  return Promise.reject(error);
});

// Response interceptor
http.interceptors.response.use(
  response => response,
  async error => {
    if (error.response && error.response.status === 401) {
      console.warn('401 Unauthorized hatası alındı, token yenileniyor...');

      const originalRequest = error.config;

      // Refresh token kullanılarak yeni access token alın
      const newAccessToken = await refreshAccessToken();

      if (newAccessToken) {
        // Yeniden isteği gönder
        originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
        return http(originalRequest);
      }
    }

    // Refresh token da başarısız olursa kullanıcıyı login sayfasına yönlendir
    if (error.response && error.response.status === 401) {
      openNotification('error', error.response.status, 'Giriş yapınız');
      cancelTokens.forEach(source => source.cancel('Operation canceled due to 401 error'));
      clearStorage();
      setTimeout(() => {
        window.location.href = '/login';
      }, 500);
    }

    return Promise.reject(error);
  }
);

const refreshAccessToken = async () => {
  try {
    const refreshToken = localStorage.getItem('refreshToken');
    if (!refreshToken) {
      throw new Error('Refresh token bulunamadı');
    }

    const response = await withoutAccessHttp.post('/auth/refresh', { refreshToken });

    // Yeni token'ları kaydet
    localStorage.setItem('accessToken', response.data.accessToken);
    localStorage.setItem('refreshToken', response.data.refreshToken);

    // Yeni token'ı header'a ekle
    http.defaults.headers['Authorization'] = `Bearer ${response.data.accessToken}`;
    return response.data.accessToken;
  } catch (error) {
    console.error('Refresh token yenileme başarısız:', error);
    clearStorage();
    window.location.href = '/login';
    return null;
  }
};