import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { formatDate } from '../../utils';
import TrashIcon from './../../assets/icons/trash.svg';
import PenIcon from './../../assets/icons/pen.svg';
import React, { useEffect, useState, useCallback } from 'react';
import {
  Table,
  Button,
  Popconfirm,
  Card,
  Spin,
  Tooltip,
  Tag,
  Select,
  Row,
  Col,
  Typography
} from 'antd';
import { deleteTicket, getAllTickets } from './Service/TicketService';
import NewTicketDrawer from './NewTicketDrawer';
import EditTicketDrawer from './EditTicketDrawer';
import { Ticket } from './Type/Ticket';
import UserService from './../../services/UserService';
import { debounce } from 'lodash';

const { Option } = Select;
const { Title } = Typography;

const TicketList: React.FC = () => {
  const [tickets, setTickets] = useState<Ticket[]>([]);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState<Ticket | null>(null);
  const [loading, setLoading] = useState(false);

  // Kullanıcı seçimi için state
  const [users, setUsers] = useState<any[]>([]);
  const [selectedUserId, setSelectedUserId] = useState<number | null>(null);
  const [userPage, setUserPage] = useState(1);
  const [userTotal, setUserTotal] = useState(0);
  const [userSearchTerm, setUserSearchTerm] = useState('');

  // Durum seçimi için state (OPEN, CLOSED veya undefined)
  const [statusFilter, setStatusFilter] = useState<string | undefined>(undefined);

  // ---------------- Client-Side Pagination State (Ön Yüzde) -------------
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10
  });

  /**
   * Parametrelere göre ticketları getirir.
   * - accountId varsa ?accountId=... ekler
   * - status varsa &status=... ekler
   */
  const fetchTickets = async (accountId?: number | null, status?: string) => {
    setLoading(true);
    try {
      let paramStr = '';
      if (accountId) {
        // ilk parametre ?accountId=...
        paramStr += `?accountId=${accountId}`;
      }
      if (status) {
        // eğer paramStr boş değilse & ekle, yoksa ?
        paramStr += paramStr ? `&status=${status}` : `?status=${status}`;
      }

      const data: Ticket[] = await getAllTickets(paramStr);
      const sorted = data.sort(
        (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      );
      setTickets(sorted);

      // Yeni veri geldiğinde tabloyu ilk sayfaya alabiliriz
      setPagination((prev) => ({ ...prev, current: 1 }));
    } catch (error) {
      console.error('Failed to fetch tickets:', error);
    } finally {
      setLoading(false);
    }
  };

  /**
   * Kullanıcıları listelemek için
   */
  const fetchUsers = async (page: number = 1, search: string = '') => {
    try {
      const response: any = await UserService.getAll({
        name: search,
        page: page - 1, // Spring: 0-index
        size: 10,
        sortField: 'createdAt',
        sortDirection: 'DESC',
      });
      if (page === 1) {
        setUsers(response.data.content);
      } else {
        setUsers((prev) => [...prev, ...response.data.content]);
      }
      setUserTotal(response.data.totalElements);
    } catch (error) {
      console.error('Failed to fetch users:', error);
    }
  };

  /**
   * İlk açılışta tüm ticketları ve kullanıcıları getir.
   */
  useEffect(() => {
    fetchTickets(); // parametresiz => tüm ticketlar
    fetchUsers();
  }, []);

  /**
   * Kullanıcı seçimi değiştiğinde ticketları yeniden çek.
   */
  const handleUserChange = (userId: number | null) => {
    setSelectedUserId(userId);
    fetchTickets(userId, statusFilter);
  };

  /**
   * Durum seçimi değiştiğinde ticketları yeniden çek.
   */
  const handleStatusChange = (value: string | undefined) => {
    setStatusFilter(value);
    fetchTickets(selectedUserId, value);
  };

  /**
   * Debounce kullanıcı arama
   */
  const debouncedUserSearch = useCallback(
    debounce((value: string) => {
      setUserPage(1);
      setUsers([]);
      fetchUsers(1, value);
    }, 300),
    []
  );

  const handleUserSearch = (value: string) => {
    setUserSearchTerm(value);
    debouncedUserSearch(value);
  };

  const handleUserScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop, clientHeight, scrollHeight } = event.target as HTMLElement;
    if (scrollTop + clientHeight >= scrollHeight) {
      if (users.length < userTotal) {
        const nextPage = userPage + 1;
        setUserPage(nextPage);
        fetchUsers(nextPage, userSearchTerm);
      }
    }
  };

  /**
   * Silme işlemi
   */
  const handleDelete = async (id: number) => {
    setLoading(true);
    try {
      await deleteTicket(id);
      await fetchTickets(selectedUserId, statusFilter); // yenile
    } catch (error) {
      console.error('Failed to delete ticket:', error);
    } finally {
      setLoading(false);
    }
  };

  /**
   * Öncelik veya durum metnini renklendirmek
   */
  const translatePriorityOrStatus = (value: string) => {
    switch (value) {
      case 'LOW':
        return <Tag color="green">Düşük</Tag>;
      case 'MEDIUM':
        return <Tag color="orange">Orta</Tag>;
      case 'HIGH':
        return <Tag color="red">Yüksek</Tag>;
      case 'OPEN':
        return <Tag color="orange">Açık</Tag>;
      case 'CLOSED':
        return <Tag color="green">Kapalı</Tag>;
      default:
        return <Tag>{value}</Tag>;
    }
  };

  const columns = [
    {
      title: 'Ticket ID',
      dataIndex: 'id',
      key: 'id',
      width: 100,
      render: (text: number) => <b>#{text}</b>
    },
    { title: 'Konu', width: 400, dataIndex: 'subject', key: 'subject' },
    {
      title: 'Kullanıcı',
      dataIndex: 'account',
      key: 'account',
      render: (account: any) => `${account.firstName} ${account.lastName}`,
    },
    {
      title: 'Oluşturma Tarihi',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 150,
      render: (text: string) => formatDate(text)
    },
    {
      title: 'Öncelik',
      dataIndex: 'priority',
      key: 'priority',
      width: 90,
      render: (text: string) => translatePriorityOrStatus(text)
    },
    {
      title: 'Durum',
      dataIndex: 'status',
      key: 'status',
      width: 90,
      render: (text: string) => translatePriorityOrStatus(text)
    },
    {
      title: 'İşlem',
      key: 'action',
      render: (text: any, record: Ticket) => (
        <div style={{ display: 'flex', gap: '16px' }}>
          <Tooltip title="Düzenle">
            <Button
              icon={<img src={PenIcon} alt="pen" className="icon" />}
              shape="circle"
              onClick={() => {
                setSelectedTicket(record);
                setDrawerVisible(true);
              }}
              className='icon-button'
            />
          </Tooltip>
          <Tooltip title="Sil">
            <Popconfirm
              title="Bu destek talebini silmek istediğinizden emin misiniz?"
              onConfirm={() => handleDelete(record.id)}
              okText="Evet"
              cancelText="Hayır"
            >
              <Button
                icon={<img src={TrashIcon} alt="trash" className="icon" />}
                shape="circle"
                className='icon-button'
              />
            </Popconfirm>
          </Tooltip>
        </div>
      ),
    },
  ];

  // -----------------------------------
  // Ant Design Table OnChange (pagination)
  // -----------------------------------
  const handleTableChange = (newPagination: any) => {
    setPagination((prev) => ({
      ...prev,
      current: newPagination.current,
      pageSize: newPagination.pageSize
    }));
  };

  // Client-side pagination: slice
  const startIndex = (pagination.current - 1) * pagination.pageSize;
  const endIndex = startIndex + pagination.pageSize;
  const slicedData = tickets.slice(startIndex, endIndex);

  return (
    <div className='ticket-list-container mb-120px'>
      <Card className='br-30px'>
        <Row gutter={[16, 16]} align="middle" justify="space-between" className="mb-20px">
          <Col xs={24} sm={12} lg={16}>
            <Title level={4} style={{ margin: 0 }}>
              Destek Talepleri
            </Title>
          </Col>

          {/* Filtreler */}
          <Col xs={24} sm={12} lg={8} style={{ textAlign: 'center' }} className="d-flex justify-content-end">
            {/* Kullanıcı Seçimi */}
            <Select
              showSearch
              placeholder="Kullanıcı Seçin"
              style={{ width: '200px', marginRight: 8 }}
              className='d-input-filter'
              onChange={(value) => handleUserChange(value as number)}
              onSearch={handleUserSearch}
              onPopupScroll={handleUserScroll}
              allowClear
              filterOption={false}
            >
              {users.map((user: any) => (
                <Option key={user.id} value={user.id}>
                  {`${user.firstName} ${user.lastName}`}
                </Option>
              ))}
            </Select>

            {/* Durum Seçimi (Açık / Kapalı) */}
            <Select
              placeholder="Durum Seçin"
              className='d-input-filter'
              style={{ width: '150px' }}
              allowClear
              onChange={(value) => handleStatusChange(value as string)}
            >
              <Option value="OPEN">Açık</Option>
              <Option value="CLOSED">Kapalı</Option>
            </Select>
          </Col>
        </Row>

        <Spin spinning={loading}>
          <Table
            columns={columns}
            // Sadece slice edilmiş veriyi veriyoruz (client-side pagination)
            dataSource={slicedData}
            rowKey="id"
            scroll={{ x: 'max-content' }}
            // Pagination ayarları
            pagination={{
              current: pagination.current,
              pageSize: pagination.pageSize,
              total: tickets.length,
              showSizeChanger: true,
              pageSizeOptions: ['5', '10', '20', '50'],
            }}
            onChange={handleTableChange}
          />
        </Spin>

        {selectedTicket ? (
          <EditTicketDrawer
            visible={drawerVisible}
            onClose={() => setDrawerVisible(false)}
            ticketId={selectedTicket.id}
            onSave={() => fetchTickets(selectedUserId, statusFilter)}
            initialValues={selectedTicket}
          />
        ) : (
          <NewTicketDrawer
            visible={drawerVisible}
            onClose={() => setDrawerVisible(false)}
            onSave={() => fetchTickets(selectedUserId, statusFilter)}
            initialValues={{ priority: 'LOW' }}
          />
        )}
      </Card>
    </div>
  );
};

export default TicketList;