import { Result } from 'antd';
import { SmileOutlined } from '@ant-design/icons';

 const NoData = () => {
  return (
    <Result
    icon={<SmileOutlined />}
    title="Buralar denetlenmiş gibi gözüküyor!"
    />
  );
};

export default NoData;
