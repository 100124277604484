import { http } from "../http-common";

interface NotificationQueryParams {
  page?: number;
  size?: number;
  status?: string;
  type?: string;
  startDate?: string;
  endDate?: string;
}

const getMeNotificationsSent = (params?: NotificationQueryParams) => {
  return http.get('/announcements/notifications', {
    params: { ...params, status: "SENT" },
  });
};

const getMeNotifications = (params?: NotificationQueryParams) => {
  return http.get('/announcements/notifications', { params });
};

const listenNotificationsSSE = (
  userId: string | number,
  token: string,
  onNotification: (data: string) => void,
  onError?: (error: any) => void
) => {
  const baseUrl = process.env.REACT_APP_API_BASE_URL || "http://localhost:8082";
  const url = `${baseUrl}/notifications/sse/${userId}?token=${token}`;

  const eventSource = new EventSource(url);

  eventSource.addEventListener("notification", (event) => {
    if (onNotification) {
      onNotification(event.data);
    }
  });

  eventSource.onerror = (err) => {
    console.error("SSE error:", err);
    eventSource.close();
    if (onError) {
      onError(err);
    }
  };

  return eventSource;
};

const markAsRead = (notificationId: any) => {
  return http.post(`/notifications/read?notificationId=${notificationId}`);
};

const NotificationService = {
  getMeNotificationsSent,
  listenNotificationsSSE,
  markAsRead,
  getMeNotifications,
};

export default NotificationService;