import React, { useEffect, useState } from 'react';
import { Badge, Popover, Row, Tooltip, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import NotificationService from '../../services/NotificationService';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { CheckCircleOutlined, CloseOutlined } from '@ant-design/icons';
import NotifyIcon from './../../assets/icons/notify.svg';
import './Notifications.css';
import NoData from '../../views/NoData';
import { formatDate } from '../../utils';

const { Text } = Typography;

function Notifications() {
  const [notifications, setNotifications] = useState<any[]>([]);
  const [popoverVisible, setPopoverVisible] = useState(false);
  const navigate = useNavigate();

  /**
   * Bildirimleri pageable destekli şekilde çeker.
   * Burada ilk sayfa (page: 0) ve 10 kayıt (size: 10) çekiyoruz.
   */
  const fetchNotifications = () => {
    NotificationService.getMeNotificationsSent({ page: 0, size: 10 })
      .then((res: any) => {
        // Backend yanıtı pageable yapıdaysa { content: [...], totalElements: number, ... } şeklinde döner.
        const data = res.data;
        if (data && Array.isArray(data.content)) {
          const validList = data.content
            .map((item: any) =>
              parseRawMessage(
                item.message,
                item.id,
                item.createdAt ?? item.updatedAt
              )
            )
            .filter(Boolean);
          setNotifications(validList);
        }
      })
      .catch((err: any) => {
        console.error('SENT bildirimler çekilirken hata:', err);
      });
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  // SSE ile yeni bildirimleri dinleme
  useEffect(() => {
    const token = localStorage.getItem('accessToken');
    const userId = localStorage.getItem('id');
    if (!token || !userId) return;

    const es = NotificationService.listenNotificationsSSE(
      userId,
      token,
      (data: string) => {
        console.log('Yeni bildirim SSE:', data);
        if (data) {
          fetchNotifications();
        }
      },
      (err: any) => {
        console.error('SSE error:', err);
      }
    );

    return () => {
      es.close();
    };
  }, []);

  /**
   * Raw mesajın parse edilmesi.
   * Beklenen format: "6 /-/ INFORMATION /-/ title /-/ message"
   * Tarih bilgisini 5. parça olarak alabilir veya gelen createdAt/updatedAt bilgisini kullanabilirsiniz.
   */
  function parseRawMessage(raw: string, fallbackId?: number, createdAt?: any): any | null {
    if (!raw || !raw.includes('/-/')) return null;
    const parts = raw.split('/-/');
    if (parts.length < 4) return null;

    return {
      announcementId: parts[0].trim() || fallbackId || Math.random().toString(),
      id: fallbackId || 0,
      type: parts[1].trim(),
      title: parts[2].trim(),
      message: parts[3].trim(),
      date: formatDate(createdAt),
    };
  }

  // "Okundu" işaretleme
  const handleMarkAsRead = (notifId: string | number) => {
    NotificationService.markAsRead(notifId)
      .then((res) => {
        if (res.status === 200) {
          setNotifications((prev) => prev.filter((item) => item.id !== notifId));
        }
      })
      .catch((err) => {
        console.error('Okundu işaretleme hatası:', err);
      });
  };

  // Bildirim sayısı; 9'dan fazlaysa "9+"
  const notificationCount = notifications.length > 9 ? '9+' : notifications.length;

  /**
   * Popover içeriğini render eden fonksiyon.
   */
  const renderPopoverContent = () => {
    return (
      <div className="custom-notif-popover">
        {/* Üst başlık */}
        <div className="notif-header">
          <Text strong className="notif-header-title">Duyurular</Text>
          <CloseOutlined
            style={{ fontSize: 16, cursor: 'pointer' }}
            onClick={() => setPopoverVisible(false)}
          />
        </div>

        {/* İçerik */}
        {notifications.length === 0 ? (
          <div style={{ textAlign: 'center', padding: 12 }}>
            <NoData />
          </div>
        ) : (
          <div className="notif-list-container">
            <TransitionGroup component={null}>
              {notifications.map((notif) => (
                <CSSTransition key={notif.id} timeout={300} classNames="slide">
                  <div className="notif-item">
                    <div className="notif-item-header-flex">
                      <Text strong style={{ fontSize: 14, color: '#000' }}>
                        {getTurkishType(notif.type)}
                      </Text>
                      <div style={{ display: 'flex', alignItems: 'center', gap: 12 }}>
                        <Text style={{ color: '#666', fontSize: 13 }}>
                          {notif.date}
                        </Text>
                        <Tooltip title="Okundu Olarak İşaretle">
                          <CheckCircleOutlined
                            onClick={() => handleMarkAsRead(notif.id)}
                            style={{ fontSize: 18, color: '#1890ff', cursor: 'pointer' }}
                          />
                        </Tooltip>
                      </div>
                    </div>
                    <Tooltip title={notif.title}>
                      <Text style={{ fontWeight: 700, color: '#000' }}>
                        {truncateText(notif.title, 100)}
                      </Text>
                    </Tooltip>
                    <br />
                    <Tooltip title={notif.message}>
                      <Text style={{ color: '#000' }}>
                        {truncateText(notif.message, 120)}
                      </Text>
                    </Tooltip>
                  </div>
                </CSSTransition>
              ))}
            </TransitionGroup>
          </div>
        )}

        {/* Alt kısım: Tüm duyurular sayfasına yönlendirme */}
        <div
          className="notif-footer"
          onClick={() => {
            setPopoverVisible(false);
            navigate('/all-notifications');
          }}
        >
          Tüm Duyurular
        </div>
      </div>
    );
  };

  function getTurkishType(type: string) {
    switch (type) {
      case 'INFORMATION':
        return 'Bilgilendirme';
      case 'UPDATE':
        return 'Güncelleme';
      case 'EVENT':
        return 'Etkinlik';
      default:
        return 'Diğer';
    }
  }

  function truncateText(txt: string, maxLen: number) {
    if (!txt) return '';
    return txt.length <= maxLen ? txt : txt.substring(0, maxLen) + '...';
  }

  return (
    <Popover
      content={renderPopoverContent()}
      trigger="click"
      open={popoverVisible}
      onOpenChange={setPopoverVisible}
      placement="bottomRight"
      overlayClassName="custom-notif-overlay"
    >
      <Row className="notify-box" justify="center" align="middle">
        <Badge count={notificationCount} overflowCount={9}>
          <img
            src={NotifyIcon}
            alt="Bildirim"
            style={{ cursor: 'pointer', width: 24, height: 24 }}
          />
        </Badge>
      </Row>
    </Popover>
  );
}

export default Notifications;