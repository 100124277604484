import { Button, Form, Input, Row, Typography, Layout, Modal, Image } from 'antd';
import { EyeTwoTone, EyeInvisibleOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { openNotification } from './../../utils';
import './auth.scss';
import { useEffect, useState } from 'react';
import successIcon from './../../assets/auth/success-icon.png';
import Logo from '../../assets/auth/kontrolxlogo.svg';
import AgreementService from '../../services/AgreementService';

const { Title, Text, Paragraph } = Typography;
const { Content } = Layout;

const Register = () => {
  const navigate = useNavigate();

  // Modal kontrolü
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalHtml, setModalHtml] = useState<string>('');
  const [modalTitle, setModalTitle] = useState<string>(''); 
  const [activeModalKey, setActiveModalKey] = useState<'privacyPolicy' | 'terms' | 'clarification' | null>(null);

  // Kayıt sonrası başarı ekranı için
  const [isRegisterSuccess, setIsRegisterSuccess] = useState(false);
  // Kayıt butonu loading kontrolü
  const [isLoading, setIsLoading] = useState(false);

  // Tüm sözleşmelerin listesi
  const [allAgreements, setAllAgreements] = useState<any[]>([]);

  const fetchAgreements = async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_BASE_URL + '/agreements';
      let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: apiUrl,
      };

      axios.request(config)
        .then((response: any) => {
          setAllAgreements(response.data);
          setIsLoading(false);
        })
        .catch((e: any) => {
          console.log(e);
          setIsLoading(false);
        });
    } catch (error: any) {
      if (error.name === 'CanceledError') {
        console.log('İşlem kullanıcı tarafından iptal edildi');
        return;
      }
      openNotification("error", "Hata", "Sözleşmeler yüklenirken bir hata oluştu.");
    }
  };

  useEffect(() => {
    fetchAgreements();
  }, []);

  // Modal açma fonksiyonu
  const showModal = (contentKey: 'privacyPolicy' | 'terms' | 'clarification') => {
    setActiveModalKey(contentKey);

    let contractId:any;
    if (contentKey === 'clarification') {
      contractId = 2; // KVKK
    } else if (contentKey === 'terms') {
      contractId = 3; // Açık Rıza
    } else if (contentKey === 'privacyPolicy') {
      contractId = 4; // Ticari Elektronik İleti
    }

    const selectedContract = allAgreements.find((item) => item.id === contractId);
    const defaultHtml = '<p>Sözleşme metni bulunamadı.</p>';
    const contractHtml = selectedContract?.contractHtml || defaultHtml;
    const contractName = selectedContract?.name || 'Sözleşme Başlığı Bulunamadı';

    setModalTitle(contractName);
    setModalHtml(contractHtml);
    setIsModalVisible(true);
  };

  // Modal kapama
  const handleOk = () => {
    setIsModalVisible(false);
    setActiveModalKey(null);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setActiveModalKey(null);
  };

  // Form submit (Kayıt işlemi)
  const onFinish = (values: any) => {
    setIsLoading(true);
    let userData = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email.toLowerCase(),
      password: values.password,
      phone: values.phone,
    };

    const apiUrl = process.env.REACT_APP_API_BASE_URL + '/auth/register';
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: apiUrl,
      data: userData,
    };

    axios.request(config)
      .then((response: any) => {
        localStorage.setItem('accessToken', response?.data?.accessToken);
        localStorage.setItem('accessTokenExpiredAt', response?.data?.accessTokenExpiredAt);
        localStorage.setItem('refreshToken', response?.data?.refreshToken);
        localStorage.setItem('refreshTokenExpiredAt', response?.data?.refreshTokenExpiredAt);

        setIsRegisterSuccess(true);
        setIsLoading(false);
      })
      .catch((e: any) => {
        console.log(userData);
        console.log(e);

        if (e.response?.data?.errorCode === 'account-already-exists') {
          openNotification('error', 'Başarısız', 'E-posta daha önce kayıt edilmiş.');
        } else {
          openNotification('error', 'Başarısız', 'Kayıt Yapılamadı.');
        }
        setIsLoading(false);
      });
  };

  return (
    <Content>
      {isRegisterSuccess ? (
        <>
          {/* Kayıt Başarılı Ekranı */}
          <Row justify="center">
            <img src={Logo} alt="Kontrol X" className="logo" height={40} style={{ margin: '20px 0 10px 0' }} />
          </Row>
          <Row justify="center">
            <Image src={successIcon} preview={false} width={64} />
          </Row>
          <Row align="middle" justify="center" className="text-center">
            <Title level={2}>Kullanıcı kayıt talebiniz başarıyla alınmıştır.</Title>
            <Paragraph className="fs-20 fw-400 font-color-subtext">
              E-posta adresinize gelen aktivasyon linkine tıklayarak kullanıcı kayıt işleminizi tamamlayabilirsiniz.
            </Paragraph>
          </Row>
        </>
      ) : (
        <>
          {/* Kayıt Formu Ekranı */}
          <Row justify="center">
            <img
              src={Logo}
              alt="Kontrol X"
              className="logo"
              height={40}
              style={{ margin: '20px 0 10px 0' }}
            />
          </Row>
          <Row justify="center">
            <Title level={2} className="form-header-title">
              Kayıt Ol
            </Title>
          </Row>
          <Form
            name="register_form"
            className="register-form"
            initialValues={{ remember: true }}
            onFinish={onFinish}
          >
            <Form.Item
              name="firstName"
              label="Ad"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              rules={[{ required: true, message: 'Lütfen adınızı giriniz!' }]}
            >
              <Input className="d-input" placeholder="Ad" />
            </Form.Item>
            <Form.Item
              name="lastName"
              label="Soyad"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              rules={[{ required: true, message: 'Lütfen soyadınızı giriniz!' }]}
            >
              <Input className="d-input" placeholder="Soyad" />
            </Form.Item>
            <Form.Item
              name="email"
              label="E-Posta"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              rules={[
                { required: true, message: 'Lütfen e-posta adresinizi giriniz!' },
                { type: 'email', message: 'Lütfen geçerli bir e-posta adresi giriniz!' },
              ]}
            >
              <Input className="d-input" placeholder="E-Posta" />
            </Form.Item>
            <Form.Item
              name="phone"
              label="Telefon"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              rules={[
                {
                  validator: (_, value) => {
                    if (!value) return Promise.resolve(); // Boş olabilir
                    if (!/^[5][0-9]{9}$/.test(value)) {
                      return Promise.reject(new Error('Telefon 5 ile başlamalı ve 10 rakamdan oluşmalıdır.'));
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input
                className="d-input"
                placeholder="Telefon"
                addonBefore="+90"
                maxLength={10}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
            </Form.Item>
            <Form.Item
              name="password"
              label="Şifre"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              rules={[
                { required: true, message: 'Lütfen şifrenizi giriniz!' },
                {
                  validator: (_: any, value: any) => {
                    const errors: string[] = [];
                    if (!value) {
                      return Promise.reject(new Error('Şifre boş bırakılamaz!'));
                    }
                    if (value.length < 8) {
                      errors.push('Şifre en az 8 karakter olmalıdır!');
                    }
                    if (!value.match(/[0-9]/)) {
                      errors.push('En az bir sayı içermelidir!');
                    }
                    if (errors.length > 0) {
                      return Promise.reject(new Error(errors.join('\n')));
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input.Password
                placeholder="Şifre"
                className="d-input"
                iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
              />
            </Form.Item>
            <Form.Item
              name="confirmPassword"
              label="Şifre Tekrarı"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              rules={[
                { required: true, message: 'Lütfen şifrenizi tekrar giriniz!' },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('Şifreler uyuşmuyor!'));
                  },
                }),
              ]}
            >
              <Input.Password
                className="d-input"
                placeholder="Şifre Tekrarı"
              />
            </Form.Item>

            <Row className="text-center mb-8px">
              <Text type="secondary">
                Kişisel verileriniz,{' '}
                <Text
                  onClick={() => showModal('clarification')}
                  style={{ color: '#000', cursor: 'pointer' }}
                >
                  Aydınlatma Metni
                </Text>{' '}
                kapsamında işlenmektedir. "Kaydol" butonuna basarak{' '}
                <Text
                  onClick={() => showModal('terms')}
                  style={{ color: '#000', cursor: 'pointer' }}
                >
                  Açık Rıza Metni
                </Text>{' '}
                'ni ve{' '}
                <Text
                  onClick={() => showModal('privacyPolicy')}
                  style={{ color: '#000', cursor: 'pointer' }}
                >
                  Ticari Elektronik İleti İzni
                </Text>{' '}
                'ni okuduğunuzu ve kabul ettiğinizi onaylıyorsunuz.
              </Text>
            </Row>

            <Form.Item>
              <Button
                htmlType="submit"
                className="d-button mt-16px"
                type="primary"
                loading={isLoading}
              >
                Kaydol
              </Button>
            </Form.Item>
          </Form>

          <Row justify="center">
            <Text>
              Zaten bir hesabım var <Link className="fw-400" to={'/login'}>Giriş Yap</Link>
            </Text>
          </Row>

          {/* Bilgilendirme Modal */}
          <Modal
            title={modalTitle}
            open={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={null}
            centered
            style={{ top: 20 }}
            bodyStyle={{ maxHeight: '500px', overflowY: 'auto' }}
          >
            <div dangerouslySetInnerHTML={{ __html: modalHtml }} />
          </Modal>
        </>
      )}
    </Content>
  );
};

export default Register;