import React, { useEffect, useState, useCallback } from "react";
import {
  Table,
  Card,
  Spin,
  Tag,
  Button,
  Select,
  message,
  Row,
  Form,
  DatePicker,
  Typography,
  notification,
  Col,
} from "antd";
import type { TablePaginationConfig } from "antd/es/table";
import type { ColumnsType, SorterResult } from "antd/es/table/interface";
import ProposalService from "../../services/ProposalService";
import StatusService from "../../modules/Status/Service/StatusService";
import UserService from "../../services/UserService";
import { formatDate, formatToTL, formatToUSD, formatToEUR } from "../../utils";
import tinycolor from "tinycolor2";
import * as XLSX from "xlsx"; // Excel export
import { saveAs } from "file-saver"; // For saving files
import { DownloadOutlined } from "@ant-design/icons";

const { RangePicker } = DatePicker;
const { Option } = Select;
const { Title } = Typography;

// Teklif verisinin tipi
interface Proposal {
  proposalNumber: string;
  createdAt: string;
  account: {
    firstName: string;
    lastName: string;
  };
  company: {
    name: string;
  };
  statusId: number;
  totalPrice: number;
}

// Özet verisinin tipi
interface SummaryData {
  totalPrice: number;
  count: number;
}

// Statü tipi
interface Status {
  id: number;
  name: string;
  color: string;
}

// Kullanıcı tipi
interface CustomerItem {
  id: number;
  firstName: string;
  lastName: string;
}

const ProposalSummaryPage: React.FC = () => {
  const [form] = Form.useForm();
  const [proposals, setProposals] = useState<Proposal[]>([]);
  const [summaryData, setSummaryData] = useState<SummaryData | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [statuses, setStatuses] = useState<Status[]>([]);
  const [totalItems, setTotalItems] = useState<number>(0);

  // Döviz kurları (USD ve EUR dönüşüm oranları)
  const [usdRate, setUsdRate] = useState<number>(1);
  const [eurRate, setEurRate] = useState<number>(1);

  // Filtre ve sıralama state'leri
  const [company, setCompany] = useState<string>("");
  const [selectedUserId, setSelectedUserId] = useState<number | null>(null);
  const [dateFilter, setDateFilter] = useState<{ startDate: string | null; endDate: string | null }>({
    startDate: null,
    endDate: null,
  });
  // Statü filtresini tekli seçim olarak tutuyoruz
  const [selectedStatusId, setSelectedStatusId] = useState<number | null>(null);

  const [sortField, setSortField] = useState<string>("createdAt");
  const [sortDirection, setSortDirection] = useState<string>("ASC");

  // Sayfalama (antd Table 1-indexli)
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);

  // Kullanıcı listesini tutan state
  const [userList, setUserList] = useState<CustomerItem[]>([]);

  // Döviz kurlarını çek
  useEffect(() => {
    fetch("https://api.frankfurter.app/latest?from=TRY&to=USD,EUR")
      .then((res) => res.json())
      .then((data) => {
        if (data && data.rates) {
          setUsdRate(data.rates.USD);
          setEurRate(data.rates.EUR);
        }
      })
      .catch((err) => {
        console.error("Döviz kurları çekilirken hata oluştu:", err);
      });
  }, []);

  // Statüleri çek
  const fetchStatuses = async () => {
    try {
      const response: any = await StatusService.getAll();
      const allowedIds = [2, 3, 4, 5, 6, 9, 10, 11]; // Filtrelenecek statü ID'leri
      const filteredStatuses: Status[] = (response.data || []).filter((status: Status) =>
        allowedIds.includes(status.id)
      );
      setStatuses(filteredStatuses);
    } catch (error) {
      console.error("Statüler alınırken hata oluştu:", error);
      notification.error({
        message: "Hata",
        description: "Statüler alınırken hata oluştu.",
      });
    }
  };

  // Kullanıcı listesini çek (arama destekli)
  const fetchUserList = useCallback(async (searchTerm?: string) => {
    try {
      const response: any = await UserService.getAll({
        name: searchTerm || "",
        page: 0,
        size: 20,
        sortField: "createdAt",
        sortDirection: "DESC",
      });
      setUserList(response.data?.content || []);
    } catch (err) {
      console.error("Kullanıcı verileri çekilirken hata oluştu:", err);
      message.error("Kullanıcı listesi alınamadı");
    }
  }, []);

  // Teklif + Özet verisini çekme (getAll -> Paged results)
  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      // Ortak parametreler
      const commonParams: any = {
        ...(company ? { company } : {}),
        sortField,
        sortDirection,
        ...(dateFilter.startDate && { startDate: dateFilter.startDate }),
        ...(dateFilter.endDate && { endDate: dateFilter.endDate }),
        ...(selectedUserId && { accountId: selectedUserId }),
      };

      // Paginated liste
      const getAllParams = {
        ...commonParams,
        page: currentPage - 1,
        size: pageSize,
        ...(selectedStatusId && { statuIds: selectedStatusId.toString() }),
      };

      const response: any = await ProposalService.getAll(getAllParams);
      let proposalsData: Proposal[] = [];
      if (Array.isArray(response.data)) {
        proposalsData = response.data;
      } else if (response.data.content && Array.isArray(response.data.content)) {
        proposalsData = response.data.content;
      }
      setProposals(proposalsData);
      setTotalItems(response.data.totalElements);

      // Özet (statusId)
      const summaryParams = {
        ...commonParams,
        ...(selectedStatusId && { statusId: selectedStatusId }),
      };
      const summaryResponse = await ProposalService.summaryByDateStatus(summaryParams);
      setSummaryData(summaryResponse.data);
    } catch (error) {
      console.error("Teklif verileri alınırken hata oluştu:", error);
      notification.error({
        message: "Hata",
        description: "Teklif verileri alınırken hata oluştu.",
      });
    } finally {
      setLoading(false);
    }
  }, [
    company,
    selectedUserId,
    dateFilter,
    selectedStatusId,
    currentPage,
    pageSize,
    sortField,
    sortDirection,
  ]);

  // componentDidMount
  useEffect(() => {
    fetchStatuses();
  }, []);

  // Kullanıcı listesini yükle
  useEffect(() => {
    fetchUserList();
  }, [fetchUserList]);

  // Data yükle
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // Form değişimlerini yakala
  const onValuesChange = (changedValues: any, allValues: any) => {
    if ("dateRange" in changedValues) {
      if (allValues.dateRange && allValues.dateRange.length === 2) {
        const startDate = allValues.dateRange[0].format("YYYY-MM-DD");
        const endDate = allValues.dateRange[1].format("YYYY-MM-DD");
        setDateFilter({ startDate, endDate });
      } else {
        setDateFilter({ startDate: null, endDate: null });
      }
    }
    if ("status" in changedValues) {
      setSelectedStatusId(allValues.status || null);
    }
    if ("company" in changedValues) {
      setCompany(allValues.company || "");
    }
    if ("user" in changedValues) {
      setSelectedUserId(allValues.user || null);
    }
    setCurrentPage(1);
  };

  // Tablo sayfa/sort değişimlerini yakala
  const handleTableChange = (
    pagination: TablePaginationConfig,
    _filters: any,
    sorter: SorterResult<Proposal> | SorterResult<Proposal>[]
  ) => {
    if (pagination.current) {
      setCurrentPage(pagination.current);
    }
    if (pagination.pageSize) {
      setPageSize(pagination.pageSize);
    }
    const singleSorter = Array.isArray(sorter) ? sorter[0] : sorter;
    if (singleSorter && singleSorter.field && singleSorter.order && singleSorter.field !== "statusId") {
      setSortField(singleSorter.field as string);
      setSortDirection(singleSorter.order === "ascend" ? "ASC" : "DESC");
    } else {
      setSortField("createdAt");
      setSortDirection("ASC");
    }
  };

  // Statü yardımı
  const getStatusNameById = (statusId: number) => {
    const status = statuses.find((st) => st.id === statusId);
    return status ? status.name : "Bilinmiyor";
  };
  const getStatusColorById = (statusId: number) => {
    const status = statuses.find((st) => st.id === statusId);
    return status ? status.color : "#fdfdfd";
  };

  /**
   * Excel’e indirirken getAllWithoutPagination API’sini kullanır:
   * Böylece tüm veriyi (filtreli veya filtresiz) çeker, paginated değil.
   */
  const handleExportExcel = async () => {
    try {
      setLoading(true);

      // Filtre parametreleri (paginated parametreler yok)
      const exportParams = {
        ...(company ? { company } : {}),
        sortField,
        sortDirection,
        ...(dateFilter.startDate && { startDate: dateFilter.startDate }),
        ...(dateFilter.endDate && { endDate: dateFilter.endDate }),
        ...(selectedUserId && { accountId: selectedUserId }),
        ...(selectedStatusId && { statuIds: selectedStatusId.toString() }),
      };

      // getAllWithoutPagination servisi, tüm veriyi döndürecek
      const response: any = await ProposalService.getAllWithoutPagination(exportParams);
      const data: Proposal[] = Array.isArray(response.data) ? response.data : [];

      // Excel'e aktarılacak veri
      const exportData = data.map((proposal) => {
        // Hesaplamalar: varsayılan stopaj oranı %20 olarak kabul edilmiştir.
        const grossPrice = proposal.totalPrice / 0.8;
        const withholdingTax = grossPrice - proposal.totalPrice;
        return {
          "Teklif Numarası": proposal.proposalNumber,
          "Teklif Tarihi": formatDate(proposal.createdAt),
          "İş Ortağı": `${proposal.account.firstName} ${proposal.account.lastName}`,
          "Firma Adı": proposal.company.name,
          "Statü": getStatusNameById(proposal.statusId),
          "Teklif Tutarı (TL)": formatToTL(proposal.totalPrice),
          "Brüt Tutar (TL)": formatToTL(grossPrice),
          "Stopaj Tutarı (TL)": formatToTL(withholdingTax),
          "Teklif Tutarı (USD)": usdRate ? formatToUSD(proposal.totalPrice * usdRate) : "-",
          "Teklif Tutarı (EUR)": eurRate ? formatToEUR(proposal.totalPrice * eurRate) : "-",
        };
      });

      const worksheet = XLSX.utils.json_to_sheet(exportData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Proposals");

      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      const blob = new Blob([excelBuffer], {
        type: "application/octet-stream",
      });
      saveAs(blob, "proposals.xlsx");
    } catch (err) {
      console.error("Excel export error:", err);
      message.error("Excel indirirken hata oluştu");
    } finally {
      setLoading(false);
    }
  };

  // Tablonun sütunları
  const columns: ColumnsType<Proposal> = [
    {
      title: "Teklif Numarası",
      dataIndex: "proposalNumber",
      key: "proposalNumber",
      sorter: true,
    },
    {
      title: "Oluşturulma Tarihi",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: true,
      render: (date: string) => formatDate(date),
    },
    {
      title: "İş Ortağı",
      key: "account",
      sorter: true,
      render: (_: any, record: Proposal) =>
        `${record.account.firstName} ${record.account.lastName}`,
    },
    {
      title: "Firma Adı",
      dataIndex: ["company", "name"],
      key: "companyName",
      sorter: true,
      width: 400,
    },
    {
      title: "Statü",
      dataIndex: "statusId",
      key: "statusId",
      sorter: false,
      width: 180,
      render: (statusId: number) => {
        const color = tinycolor(getStatusColorById(statusId));
        const transparentColor = color.setAlpha(0.1).toRgbString();
        const transparentColorBorder = color.setAlpha(0.5).toRgbString();
        return (
          <Tag
            style={{
              color: getStatusColorById(statusId),
              backgroundColor: transparentColor,
              borderColor: transparentColorBorder,
              borderRadius: "12px",
              padding: "5px 10px",
              fontSize: "14px",
              minWidth: "100px",
              textAlign: "center",
              fontWeight: 500,
            }}
          >
            {getStatusNameById(statusId)}
          </Tag>
        );
      },
    },
    {
      title: "Teklif Tutarı (TL)",
      dataIndex: "totalPrice",
      key: "totalPrice",
      sorter: true,
      render: (price: number) => formatToTL(price),
    },
    {
      title: "Teklif Tutarı (USD)",
      key: "usdPrice",
      sorter: true,
      render: (_: any, record: Proposal) => {
        if (usdRate) {
          const usdValue = record.totalPrice * usdRate;
          return formatToUSD(usdValue);
        }
        return "-";
      },
    },
    {
      title: "Teklif Tutarı (EUR)",
      key: "eurPrice",
      sorter: true,
      render: (_: any, record: Proposal) => {
        if (eurRate) {
          const eurValue = record.totalPrice * eurRate;
          return formatToEUR(eurValue);
        }
        return "-";
      },
    },
  ];

  return (
    <Card style={{ marginBottom: "120px" }}>
      <Spin spinning={loading}>
        {/* Filtre Formu */}
        <Form
          form={form}
          layout="inline"
          onValuesChange={onValuesChange}
          style={{ marginBottom: "16px", width: "100%" }}
        >
          <Row align="middle" justify={"end"} style={{ width: "100%" }}>
            <Form.Item name="dateRange">
              <RangePicker format="YYYY-MM-DD" />
            </Form.Item>
            <Form.Item name="status">
              <Select placeholder="Statü Seçiniz" style={{ width: 250 }} allowClear>
                {statuses.map((status: Status) => (
                  <Option key={status.id} value={status.id}>
                    {status.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="user">
              <Select<number>
                showSearch
                allowClear
                placeholder="İş Ortağı Seçiniz"
                style={{ width: 250 }}
                onSearch={fetchUserList}
                filterOption={false}
              >
                {userList.map((user: CustomerItem) => (
                  <Option key={user.id} value={user.id}>
                    {user.firstName} {user.lastName}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            {/* Excel İndir Butonu - getAllWithoutPagination */}
            <Form.Item>
              <Button
                type="default"
                onClick={handleExportExcel}
                icon={<DownloadOutlined />}
                className="width-auto d-button"
              >
                Excel İndir
              </Button>
            </Form.Item>
          </Row>
        </Form>

        {/* Özet Kartları */}
        {summaryData && (
          <Row gutter={[16, 16]} style={{ marginBottom: "16px" }}>
            <Col xs={24} sm={12} md={6}>
              <Card bordered={false} style={{ backgroundColor: "#FAFAFA", borderRadius: 8 }}>
                <Typography.Text strong style={{ fontSize: "16px", color: "#777C88" }}>
                  Teklif Sayısı
                </Typography.Text>
                <div style={{ fontSize: "24px", fontWeight: "bold", marginTop: "8px" }}>
                  {summaryData.count}
                </div>
              </Card>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Card bordered={false} style={{ backgroundColor: "#FAFAFA", borderRadius: 8 }}>
                <Typography.Text strong style={{ fontSize: "16px", color: "#777C88" }}>
                  Toplam Teklif Tutarı (TL)
                </Typography.Text>
                <div style={{ fontSize: "24px", fontWeight: "bold", marginTop: "8px" }}>
                  {formatToTL(summaryData.totalPrice)}
                </div>
              </Card>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Card bordered={false} style={{ backgroundColor: "#FAFAFA", borderRadius: 8 }}>
                <Typography.Text strong style={{ fontSize: "16px", color: "#777C88" }}>
                  Toplam Teklif Tutarı (USD)
                </Typography.Text>
                <div style={{ fontSize: "24px", fontWeight: "bold", marginTop: "8px" }}>
                  {usdRate ? formatToUSD(summaryData.totalPrice * usdRate) : "-"}
                </div>
              </Card>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Card bordered={false} style={{ backgroundColor: "#FAFAFA", borderRadius: 8 }}>
                <Typography.Text strong style={{ fontSize: "16px", color: "#777C88" }}>
                  Toplam Teklif Tutarı (EUR)
                </Typography.Text>
                <div style={{ fontSize: "24px", fontWeight: "bold", marginTop: "8px" }}>
                  {eurRate ? formatToEUR(summaryData.totalPrice * eurRate) : "-"}
                </div>
              </Card>
            </Col>
          </Row>
        )}

        {/* Paginated tablo */}
        <Table<Proposal>
          columns={columns}
          dataSource={proposals}
          rowKey="proposalNumber"
          pagination={{
            current: currentPage,
            pageSize: pageSize,
            showSizeChanger: true,
            pageSizeOptions: ["10", "20", "30"],
            total: totalItems,
          }}
          onChange={handleTableChange}
        />
      </Spin>
    </Card>
  );
};

export default ProposalSummaryPage;