import React, { useState, useEffect } from 'react';
import {
  Card,
  Row,
  Col,
  Radio,
  Select,
  DatePicker,
  Typography,
  message,
  Tooltip,
  Pagination,
} from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { CheckCircleOutlined } from '@ant-design/icons';
import NotificationService from '../../services/NotificationService';

const { RangePicker } = DatePicker;
const { Title, Text } = Typography;

/**
 * Duyuru tipini Türkçe gösteren yardımcı fonksiyon
 */
function getTurkishType(type: string) {
  switch (type) {
    case 'INFORMATION':
      return 'Bilgilendirme';
    case 'UPDATE':
      return 'Güncelleme';
    case 'EVENT':
      return 'Etkinlik';
    default:
      return 'Diğer';
  }
}

/**
 * Backend "id /-/ type /-/ title /-/ message" formatı varsayılıyor.
 * "createdAt" ve "status" gibi alanlar item üzerinden geliyor.
 */
function parseRawMessage(raw: string, fallbackId: number | undefined, item: any) {
  if (!raw || !raw.includes('/-/')) return null;
  const parts = raw.split('/-/');
  if (parts.length < 4) return null;
  return {
    announcementId: parts[0].trim() || fallbackId || Math.random().toString(),
    id: fallbackId || 0,
    type: parts[1].trim(),
    title: parts[2].trim(),
    message: parts[3].trim(),
    createdAt: item.createdAt,
    updatedAt: item.updatedAt,
    status: item.status || 'SENT',
  };
}

interface PageableResponse {
  content: any[];
  totalElements: number;
}

const AllNotificationsPage: React.FC = () => {
  const [notifications, setNotifications] = useState<any[]>([]);

  // Filtre alanları
  const [statusFilter, setStatusFilter] = useState<'ALL' | 'UNREAD'>('ALL');
  const [typeFilter, setTypeFilter] = useState<string>('ALL');
  const [dateRange, setDateRange] = useState<[Dayjs | null, Dayjs | null] | null>(null);

  // Pagination state
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalElements, setTotalElements] = useState<number>(0);

  // Filtre değiştiğinde sayfa numarasını resetle
  useEffect(() => {
    setCurrentPage(1);
  }, [statusFilter, typeFilter, dateRange]);

  // Filtreler ve pagination değiştiğinde API isteği atılır.
  useEffect(() => {
    fetchAllNotifications(currentPage, pageSize);
  }, [currentPage, pageSize, statusFilter, typeFilter, dateRange]);

  /**
   * Bildirimleri backend'den filtre parametreleriyle pageable şekilde çekme.
   * API yanıtı { content, totalElements, ... } şeklinde geliyor.
   */
  const fetchAllNotifications = async (page: number, size: number) => {
    try {
      // Sorgu parametrelerini oluşturuyoruz
      const params: any = { page: page - 1, size };

      // Eğer durum filtresi 'UNREAD' ise, API’ye status=SENT gönderiyoruz
      if (statusFilter === 'UNREAD') {
        params.status = 'SENT';
      }
      // Duyuru tipi filtresi (ALL dışı)
      if (typeFilter && typeFilter !== 'ALL') {
        params.type = typeFilter;
      }
      // Tarih aralığı filtresi: Backend, tarihleri "DD/MM/YYYY" formatında bekliyor.
      if (dateRange && dateRange[0] && dateRange[1]) {
        params.startDate = dateRange[0].format('DD/MM/YYYY');
        params.endDate = dateRange[1].format('DD/MM/YYYY');
      }

      const response = await NotificationService.getMeNotifications(params);
      const data: PageableResponse = response.data;
      const rawList = data.content;
      if (Array.isArray(rawList)) {
        const parsedList = rawList
          .map((item: any) => parseRawMessage(item.message, item.id, item))
          .filter(Boolean);
        setNotifications(parsedList);
        setTotalElements(data.totalElements);
      }
    } catch (err) {
      console.error('Bildirimler çekilirken hata:', err);
      message.error('Bildirimler yüklenemedi!');
    }
  };

  // Okundu işaretleme
  const handleMarkAsRead = async (notifId: number) => {
    try {
      await NotificationService.markAsRead(notifId);
      fetchAllNotifications(currentPage, pageSize);
    } catch (error) {
      console.error('Okundu işaretleme hatası:', error);
      message.error('Okundu işaretlemede hata oluştu!');
    }
  };

  return (
    <div style={{ padding: '24px', marginBottom: 120 }}>
      {/* Filtre Alanları */}
      <Card style={{ marginBottom: 24, borderRadius: 16 }} bodyStyle={{ padding: '24px' }}>
        <Title level={4} style={{ marginBottom: 24 }}>
          Duyuru Listesi
        </Title>
        <Row gutter={[16, 16]} align="middle">
          <Col xs={24} sm={8} md={6} lg={5}>
            <Radio.Group value={statusFilter} onChange={(e) => setStatusFilter(e.target.value)}>
              <Radio value="ALL">Tümü</Radio>
              <Radio value="UNREAD">Okunmayanlar</Radio>
            </Radio.Group>
          </Col>
          <Col xs={24} sm={8} md={6} lg={5}>
            <Select
              value={typeFilter}
              className="d-input-filter"
              onChange={(val) => setTypeFilter(val)}
              style={{ width: '100%' }}
            >
              <Select.Option value="ALL">Hepsi</Select.Option>
              <Select.Option value="INFORMATION">Bilgilendirme</Select.Option>
              <Select.Option value="UPDATE">Güncelleme</Select.Option>
              <Select.Option value="EVENT">Etkinlik</Select.Option>
            </Select>
          </Col>
          <Col xs={24} sm={8} md={12} lg={6}>
            <RangePicker
              format="DD/MM/YYYY"
              className="d-input-filter"
              onChange={(values) => {
                if (values && values.length === 2) {
                  setDateRange(values);
                } else {
                  setDateRange(null);
                }
              }}
              placeholder={['Başlangıç Tarihi', 'Bitiş Tarihi']}
              style={{ width: '100%' }}
            />
          </Col>
        </Row>
      </Card>

      {/* Duyurular Listesi */}
      <Card style={{ borderRadius: 16 }} bodyStyle={{ padding: '24px' }}>
        {notifications.length === 0 ? (
          <div style={{ textAlign: 'center', padding: '40px 0' }}>
            <Text>Hiç duyuru bulunamadı.</Text>
          </div>
        ) : (
          notifications.map((notif, idx) => (
            <div
              key={notif.id || idx}
              style={{
                backgroundColor: notif.status === 'SENT' ? '#f5f5f5' : '#ffffff',
                borderRadius: 8,
                padding: '16px',
                borderBottom: idx < notifications.length - 1 ? '1px solid #f0f0f0' : 0,
                marginBottom: 16,
              }}
            >
              <Row justify="space-between" style={{ marginBottom: 8 }}>
                <Col>
                  <Text style={{ fontSize: 16, fontWeight: 'bold', color: '#888' }}>
                    {getTurkishType(notif.type)}
                  </Text>
                </Col>
                <Col>
                  <Row align="middle" style={{ gap: 8 }}>
                    <Text style={{ color: '#666', fontSize: 14 }}>
                      {dayjs(notif.createdAt ?? notif.updatedAt).format('DD/MM/YYYY HH:mm')}
                    </Text>
                    {notif.status === 'SENT' && (
                      <Tooltip title="Okundu Olarak İşaretle">
                        <CheckCircleOutlined
                          style={{ fontSize: 20, color: '#1890ff', cursor: 'pointer' }}
                          onClick={() => handleMarkAsRead(notif.id)}
                        />
                      </Tooltip>
                    )}
                  </Row>
                </Col>
              </Row>
              <div style={{ marginBottom: 6 }}>
                <Text style={{ fontSize: 15, fontWeight: 'bold', color: '#000' }}>
                  {notif.title}
                </Text>
              </div>
              <div>
                <Text style={{ fontSize: 14, color: '#000' }}>
                  {notif.message}
                </Text>
              </div>
            </div>
          ))
        )}
      </Card>

      {/* Pagination */}
      <Row justify="end" style={{ marginTop: 24 }}>
        <Pagination
          current={currentPage}
          pageSize={pageSize}
          total={totalElements}
          showSizeChanger
          pageSizeOptions={['10', '20', '30']}
          onChange={(page, size) => {
            setCurrentPage(page);
            setPageSize(size);
          }}
        />
      </Row>
    </div>
  );
};

export default AllNotificationsPage;