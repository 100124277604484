import TrashIcon from './../../assets/icons/trash.svg';
import PenIcon from './../../assets/icons/pen.svg';
import { formatDate } from '../../utils';
import React, { useEffect, useState } from 'react';
import {
  Table,
  Button,
  Popconfirm,
  Card,
  Spin,
  Tooltip,
  Tag,
  Row,
  Col,
  Typography,
  Select,
} from 'antd';
import { getTickets, deleteTicket } from './Service/TicketService';
import NewTicketDrawer from './NewTicketDrawer';
import EditTicketDrawer from './EditTicketDrawer';
import { Ticket } from './Type/Ticket';
import { useLocation } from 'react-router-dom';

const { Title } = Typography;
const { Option } = Select;

const TicketList: React.FC = () => {
  const [tickets, setTickets] = useState<Ticket[]>([]);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState<Ticket | null>(null);
  const [loading, setLoading] = useState(false);

  // Durum filtresi: "OPEN", "CLOSED" veya undefined (temizlenmiş)
  const [statusFilter, setStatusFilter] = useState<string | undefined>();

  // --- Client-Side Pagination State ---
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });

  const location = useLocation();

  // Query parametreleri parse eden yardımcı
  const getQueryParams = (search: string) => {
    return new URLSearchParams(search);
  };

  /**
   * status varsa ?status=OPEN / ?status=CLOSED ekleyerek sunucuya istek atar
   * Tüm veriyi çeker => tickets state'ine atar
   */
  const fetchTickets = async (status?: string) => {
    setLoading(true);
    try {
      let paramStr: any = '';
      if (status) {
        paramStr = `?status=${status}`;
      }
      // Tüm data çekiliyor
      const data: Ticket[] = await getTickets(paramStr);

      // Tarihe göre sıralama (en yeni üste)
      const sorted = data.sort(
        (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      );
      setTickets(sorted);

      // Yeni veri geldiğinde, tabloyu 1. sayfaya alabilirsiniz:
      setPagination((prev) => ({ ...prev, current: 1 }));

    } catch (error) {
      console.error('Failed to fetch tickets:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // İlk açılışta (statusFilter boş) => tüm ticketlar
    fetchTickets();

    const queryParams = getQueryParams(location.search);
    const paramParametres: any = queryParams.get('parametres');
    if (paramParametres === 'help') {
      setDrawerVisible(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Status değiştiğinde (OPEN/CLOSED veya temizlendiğinde undefined)
   */
  const handleStatusChange = (value: string | undefined) => {
    setStatusFilter(value);
    fetchTickets(value);
  };

  /**
   * Silme işlemi
   */
  const handleDelete = async (id: number) => {
    setLoading(true);
    try {
      await deleteTicket(id);
      // Mevcut filtreyi koruyarak yeniden çek
      await fetchTickets(statusFilter);
    } catch (error) {
      console.error('Failed to delete ticket:', error);
    } finally {
      setLoading(false);
    }
  };

  /**
   * Öncelik ve Durum Tag rengi
   */
  const translatePriority = (priority: string) => {
    switch (priority) {
      case 'LOW':
        return <Tag color="green">Düşük</Tag>;
      case 'MEDIUM':
        return <Tag color="orange">Orta</Tag>;
      case 'HIGH':
        return <Tag color="red">Yüksek</Tag>;
      case 'OPEN':
        return <Tag color="orange">AÇIK</Tag>;
      case 'CLOSED':
        return <Tag color="green">KAPALI</Tag>;
      default:
        return null;
    }
  };

  // Tablo sütunları
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 100,
      render: (text: number) => <b>#{text}</b>,
    },
    {
      title: 'Konu',
      dataIndex: 'subject',
      width: 400,
      key: 'subject',
    },
    {
      title: 'Oluşturma Tarihi',
      dataIndex: 'createdAt',
      width: 150,
      key: 'createdAt',
      render: (text: string) => formatDate(text),
    },
    {
      title: 'Öncelik',
      dataIndex: 'priority',
      key: 'priority',
      width: 90,
      render: (text: string) => translatePriority(text),
    },
    {
      title: 'Durum',
      dataIndex: 'status',
      key: 'status',
      width: 90,
      render: (text: string) => translatePriority(text),
    },
    {
      title: 'İşlem',
      key: 'action',
      render: (text: any, record: Ticket) => (
        <div style={{ display: 'flex', gap: '16px' }}>
          <Tooltip title="Düzenle">
            <Button
              icon={<img src={PenIcon} alt="pen" className="icon" />}
              shape="circle"
              onClick={() => {
                setSelectedTicket(record);
                setDrawerVisible(true);
              }}
              className="icon-button"
            />
          </Tooltip>
          {/* Eğer silme ikonunu tekrar aktifleştirmek isterseniz: 
          <Tooltip title="Sil">
            <Popconfirm
              title="Bu destek talebini silmek istediğinizden emin misiniz?"
              onConfirm={() => handleDelete(record.id)}
              okText="Evet"
              cancelText="Hayır"
            >
              <Button
                icon={<img src={TrashIcon} alt="trash" className="icon" />}
                shape="circle"
                className="icon-button"
              />
            </Popconfirm>
          </Tooltip>
          */}
        </div>
      ),
    },
  ];

  // Ant Design Table onChange => pagination, filters, sorter vb. 
  const handleTableChange = (newPagination: any) => {
    setPagination((prev) => ({
      ...prev,
      current: newPagination.current,
      pageSize: newPagination.pageSize,
    }));
  };

  // Client-side pagination için slice (tabloda gösterilecek veriyi bölmek)
  const startIndex = (pagination.current - 1) * pagination.pageSize;
  const endIndex = startIndex + pagination.pageSize;
  const slicedData = tickets.slice(startIndex, endIndex);

  return (
    <div className="ticket-list-container mb-120px">
      <Card className="br-30px">
        <Row gutter={[16, 16]} align="middle" justify="space-between" className="mb-20px">
          <Col xs={24} sm={12} lg={16}>
            <Title level={4} style={{ margin: 0 }}>
              Destek Taleplerim
            </Title>
          </Col>
          <Col
            xs={24}
            sm={12}
            lg={8}
            style={{ textAlign: 'center' }}
            className="d-flex justify-content-end"
          >
            {/* Yalnızca OPEN/CLOSED durum seçimi */}
            <Select
              placeholder="Durum Seçin"
              style={{ width: 130, marginRight: 12 }}
              className='d-input-filter'
              allowClear
              onChange={(value) => handleStatusChange(value as string)}
            >
              <Option value="OPEN">Açık</Option>
              <Option value="CLOSED">Kapalı</Option>
            </Select>

            <Button
              type="primary"
              className="dark-button d-button width-auto"
              onClick={() => {
                setSelectedTicket(null);
                setDrawerVisible(true);
              }}
            >
              Yeni Destek Talebi Oluştur
            </Button>
          </Col>
        </Row>

        <Spin spinning={loading}>
          <div style={{ overflowX: 'auto' }}>
            <Table
              columns={columns}
              // Client-side pagination: sadece slice edilmiş veriyi veriyoruz
              dataSource={slicedData}
              rowKey="id"
              scroll={{ x: 'max-content' }}
              // Pagination ayarları
              pagination={{
                current: pagination.current,
                pageSize: pagination.pageSize,
                total: tickets.length,
                showSizeChanger: true,
                pageSizeOptions: ['5', '10', '20', '50'],
              }}
              onChange={handleTableChange}
            />
          </div>
        </Spin>

        {selectedTicket ? (
          <EditTicketDrawer
            visible={drawerVisible}
            onClose={() => setDrawerVisible(false)}
            ticketId={selectedTicket.id}
            onSave={() => fetchTickets(statusFilter)}
            initialValues={selectedTicket}
          />
        ) : (
          <NewTicketDrawer
            visible={drawerVisible}
            onClose={() => setDrawerVisible(false)}
            onSave={() => fetchTickets(statusFilter)}
            initialValues={{ priority: 'LOW' }}
          />
        )}
      </Card>
    </div>
  );
};

export default TicketList;