import React, { useEffect, useState } from 'react';
import { Drawer, Button, Spin, List, Typography, Divider, Popconfirm, Row, Col, Empty, Descriptions, Tag, Collapse, message, Modal } from 'antd';
import { FileOutlined } from '@ant-design/icons';
import { getTicketDetails, deleteTicketAttachment, getTicketAttachment, updateTicket } from './Service/TicketService';
import { Ticket, TicketAttachment } from './Type/Ticket';
import { formatDate, openNotification } from '../../utils';
import TicketDetail from './TicketDetail';
import FileUploadModal from './FileUploadModal';

const { Title } = Typography;
const { Panel } = Collapse;

interface EditTicketDrawerProps {
  visible: boolean;
  onClose: () => void;
  ticketId: number;
  onSave: () => void;
  initialValues: Partial<Ticket>;
}

const EditTicketDrawer: React.FC<EditTicketDrawerProps> = ({ visible, onClose, ticketId, onSave, initialValues }) => {
  const [loading, setLoading] = useState(false);
  const [ticket, setTicket] = useState<Ticket | null>(null);
  const [uploadModalVisible, setUploadModalVisible] = useState(false);
  const [activeKey, setActiveKey] = useState<string | string[]>('1');
  const [isImageModalVisible, setIsImageModalVisible] = useState(false);
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const [imageOriginalUrl, setImageOriginalUrl] = useState<any>(null);

  useEffect(() => {
    if (visible && ticketId) {
      fetchTicketDetails(ticketId);
    } else {
      setTicket(null);
    }
  }, [visible, ticketId]);

  const fetchTicketDetails = async (ticketId: number) => {
    setLoading(true);
    try {
      const ticketDetails = await getTicketDetails(ticketId);
      setTicket(ticketDetails);
    } catch (error) {
      openNotification('error', 'Hata', 'Destek talebi detayları alınırken bir hata oluştu');
    } finally {
      setLoading(false);
    }
  };

  const handleEndSupport = async (ticketId: number) => {
    setLoading(true);
    try {
      await updateTicket(ticketId, { status: 'CLOSED' });
      message.success('Destek talebi başarıyla sonlandırıldı.');
      fetchTicketDetails(ticketId); // Durum güncellendikten sonra detayları yenile
      onSave();
    } catch (error) {
      message.error('Destek talebi sonlandırılırken bir hata oluştu.');
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteAttachment = async (attachmentId: number) => {
    if (!ticket) return;
    setLoading(true);
    try {
      await deleteTicketAttachment(attachmentId);
      openNotification('success', 'Başarılı', 'Dosya başarıyla silindi');
      fetchTicketDetails(ticket.id);
    } catch (error) {
      openNotification('error', 'Hata', 'Dosya silinirken bir hata oluştu');
    } finally {
      setLoading(false);
    }
  };

  const handleAttachmentClick = async (attachmentId: number) => {
    try {
      const attachmentResponse = await getTicketAttachment(attachmentId);
      const preSignUrl = attachmentResponse.filePreSignUrl;

      const fileType = preSignUrl.split('?')[0].split('.').pop()?.toLowerCase();
      const isImage = fileType === 'jpg' || fileType === 'jpeg' || fileType === 'png' || fileType === 'gif';
      if (isImage) {
        setImageSrc(preSignUrl);
        setImageOriginalUrl(preSignUrl);
        setIsImageModalVisible(true);
      } else {
        const link = document.createElement('a');
        link.href = preSignUrl;
        link.setAttribute('download', 'file');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      message.error('Dosya alınırken hata oluştu.');
    }
  };

  const getPriorityTag = (priority: string) => {
    switch (priority) {
      case 'LOW':
        return <Tag color="green">Düşük</Tag>;
      case 'MEDIUM':
        return <Tag color="orange">Orta</Tag>;
      case 'HIGH':
        return <Tag color="red">Yüksek</Tag>;
      default:
        return null;
    }
  };

  return (
    <Drawer
      title={`Destek Talebi Detay`}
      width={720}
      onClose={onClose}
      visible={visible}
      bodyStyle={{ paddingBottom: 80 }}
    >
      <div className='ticket-container'>
        <Spin spinning={loading}>
          {ticket && (
            <Descriptions bordered column={1} labelStyle={{ fontWeight: 'bold', backgroundColor: '#f0f2f5' }}>
              <Descriptions.Item label="Destek No" contentStyle={{ fontWeight: 'bold' }}>#{ticket.id}</Descriptions.Item>
              <Descriptions.Item label="Konu">{ticket.subject}</Descriptions.Item>
              {/* <Descriptions.Item label="Açıklama">{ticket.description}</Descriptions.Item> */}
              <Descriptions.Item label="Öncelik">{getPriorityTag(ticket.priority)}</Descriptions.Item>
              <Descriptions.Item label="Kayıt Tarihi">{formatDate(ticket.createdAt)}</Descriptions.Item>
              <Descriptions.Item label="Desteği Sonlandır">
                <Popconfirm
                  title="Desteği sonlandırmak istediğinize emin misiniz?"
                  onConfirm={() => handleEndSupport(ticket.id)}
                  okText="Evet"
                  cancelText="Hayır"
                >
                  <Button type="primary" disabled={ticket.status == 'CLOSED'}>
                    Desteği Sonlandır
                  </Button>
                </Popconfirm>
              </Descriptions.Item>
            </Descriptions>
          )}
          <Collapse collapsible="icon" style={{ marginTop: 16 }} activeKey={activeKey} onChange={(key) => setActiveKey(key)}>
            <Panel
              header={
                <Row justify={"space-between"} align={"middle"}>
                  <Col>
                    <Title style={{ padding: 0, margin: 0 }} level={5}>Dosyalar</Title>
                  </Col>
                </Row>
              }
              key="1"
            >
              {ticket && ticket.attachments.length > 0 ? (
                <List
                  style={{ padding: "0px 16px" }}
                  dataSource={ticket.attachments}
                  renderItem={(attachment: TicketAttachment) => (
                    <List.Item
                      style={{ padding: 0 }}
                      actions={[
                        <Popconfirm
                          title="Bu dosyayı silmek istediğinize emin misiniz?"
                          onConfirm={() => handleDeleteAttachment(attachment.id)}
                          okText="Evet"
                          cancelText="Hayır"
                        >
                          <Button danger type="link">
                            Sil
                          </Button>
                        </Popconfirm>,
                      ]}
                    >
                      <Button type="link" onClick={() => handleAttachmentClick(attachment.id)}>
                        <FileOutlined /> {attachment.filePath}
                      </Button>
                    </List.Item>
                  )}
                />
              ) : (
                <Empty style={{ margin: 24 }} description="Dosya mevcut değil" />
              )}
            </Panel>
          </Collapse>

          <Divider>Yanıtlar</Divider>
          <TicketDetail ticketId={ticket?.id || 0} onClose={onSave} />
        </Spin>
        <FileUploadModal
          visible={uploadModalVisible}
          onClose={() => setUploadModalVisible(false)}
          ticketId={ticket?.id || 0}
          onUploadSuccess={() => fetchTicketDetails(ticketId)}
        />

        <Modal
          visible={isImageModalVisible}
          title="Dosya"
          footer={[
            <Button key="viewOriginal" type="primary" onClick={() => window.open(imageOriginalUrl ?? '', '_blank')}>
              İndir
            </Button>,
            <Button key="close" onClick={() => setIsImageModalVisible(false)}>
              Kapat
            </Button>,
          ]}
          onCancel={() => setIsImageModalVisible(false)}
        >
          <Row justify="center">
            <img src={imageSrc ?? undefined} alt="Attachment" style={{ maxHeight: 400 }} />
          </Row>
        </Modal>
      </div>
    </Drawer>
  );
};

export default EditTicketDrawer;