import React, { useState } from 'react';
import { Tabs, Button } from 'antd';
import PartnerReportTable from './PartnerReportTable';
import ProposalSummaryPage from './ProposalSummaryPage';
import ProposalPaymentPage from './ProposalPaymentPage';

const { TabPane } = Tabs;

const ReportTabs: React.FC<any> = () => {
  const [activeKey, setActiveKey] = useState('1');

  const onChange = (key: string) => {
    setActiveKey(key);
  };

  return (
    <Tabs
      activeKey={activeKey}
      onChange={onChange}
      className="custom-tabs"
      tabBarStyle={{ borderBottom: 'none' }}
    >
      <TabPane
        key="1"
        tab={
          <Button
            type={activeKey === '1' ? 'primary' : 'default'}
            className="d-button tab-button"
          >
            İş Ortakları
          </Button>
        }
      >
        <PartnerReportTable />
      </TabPane>

      <TabPane
        key="2"
        tab={
          <Button
            type={activeKey === '2' ? 'primary' : 'default'}
            className="d-button tab-button"
          >
            Teklifler
          </Button>
        }
      >
        <ProposalSummaryPage />
      </TabPane>

      <TabPane
        key="3"
        tab={
          <Button
            type={activeKey === '3' ? 'primary' : 'default'}
            className="d-button tab-button"
          >
            Ödemeler
          </Button>
        }
      >
        <ProposalPaymentPage />
      </TabPane>
    </Tabs>
  );
};

export default ReportTabs;