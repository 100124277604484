import React, { useEffect, useState } from 'react';
import {
  Tabs,
  Button,
  Table,
  Tag,
  Space,
  Card,
  Typography,
  Modal,
  Tooltip,
  Avatar,
  Divider,
  Dropdown,
  Menu,
  InputNumber,
  Form,
} from 'antd';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { ColumnsType } from 'antd/es/table';
import './Company.scss';

import ProposalService from '../../services/ProposalService';
import { capitalizeWords, formatDate, formatToTL, openNotification } from '../../utils';
import PlanningModal from './PlanningModal';
import StatusService from '../../modules/Status/Service/StatusService';
import { InfoCircleOutlined } from '@ant-design/icons';
import tinycolor from 'tinycolor2';
import CompanyService from '../../services/CompanyService';

import DocumentUploadModal from './UploadModal';
import ProposalView from '../Proposal/ProposalView';
import ReportInvoiceModal from '../ProjectFollowUp/ReportInvoiceModal';

const { TabPane } = Tabs;
const { Text } = Typography;

const Proposals: React.FC = () => {
  const [activeKey, setActiveKey] = useState('1');
  const [proposals, setProposals] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalItems, setTotalItems] = useState(0);

  const [isPlanningModalVisible, setIsPlanningModalVisible] = useState(false);
  const [selectedProposalId, setSelectedProposalId] = useState<number | null>(null);

  const { id } = useParams<{ id: string }>();
  const [statuses, setStatuses] = useState<any[]>([]);

  // Yeni ek: permissions state
  const [permissions, setPermissions] = useState<string[]>([]);
  const [selectedProposal, setSelectedProposal] = useState<any | null>(null);
  const [isUploadModalVisible, setIsUploadModalVisible] = useState(false);
  const [isDiscountModalVisible, setIsDiscountModalVisible] = useState(false);
  const [discountedPrice, setDiscountedPrice] = useState<number | null>(null);
  const [form] = Form.useForm();
  const [invoiceModalData, setInvoiceModalData] = useState<any>();
  const [invoiceModalType, setInvoiceModalType] = useState<any>();
  const [isInvoiceModalVisible, setIsInvoiceModalVisible] = useState(false);
  const [hasFormError, setHasFormError] = useState(false);

  const navigate = useNavigate();

  const handleTabChange = (key: string) => {
    setActiveKey(key);
  };

  useEffect(() => {
    // 1) permissions'ı localStorage'dan oku
    const storedPermissions = localStorage.getItem('permissions');
    if (storedPermissions) {
      try {
        const parsedPermissions = JSON.parse(storedPermissions);
        
        // Gelen verinin gerçekten bir array olup olmadığını kontrol edelim
        if (Array.isArray(parsedPermissions)) {
          setPermissions(parsedPermissions);
        } else {
          console.error('Permissions hatalı formatta, beklenen array:', parsedPermissions);
          setPermissions([]); // Varsayılan olarak boş array ata
        }
    
      } catch (err) {
        console.error('Permissions parse hatası:', err);
        setPermissions([]); // Hata olursa boş array ata
      }
    }

    // 2) Statusleri çek
    StatusService.getAll()
      .then((response: any) => {
        setStatuses(response.data);
      })
      .catch((error) => console.error('Statüler yüklenirken hata oluştu:', error));
  }, []);

  // Teklifleri fetch et
  const fetchProposals = (page: number, size: number) => {
    const hasProposalShow = permissions.includes('proposal.show');

    // Eski "isAdmin" yerine "hasProposalShow"
    const fetchProposalsData = hasProposalShow
      ? // Tüm teklifler
        ProposalService.getAll({ page, size, companyId: id })
      : // Kendi teklifleri
        ProposalService.getMe({ page, size, companyId: id });

    fetchProposalsData
      .then(async (response: any) => {
        const companiesWithLogo = await Promise.all(
          response.data.content.map(async (item: any) => {
            if (item?.company?.imageId) {
              try {
                const logoResponse = await CompanyService.getImage(item.company.imageId);
                item.company.logoUrl = logoResponse.data.preSignUrl; // logoUrl ekle
              } catch (error) {
                console.error('Logo yüklenirken hata oluştu:', error);
              }
            }
            return item;
          })
        );
        setProposals(companiesWithLogo);
        setTotalItems(response.data.totalElements);
      })
      .catch((error) => {
        console.error('Teklif verileri alınırken hata oluştu:', error);
      });
  };

  useEffect(() => {
    fetchProposals(currentPage, pageSize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize, permissions]);

  const handlePaginationChange = (page: number, pageSize?: number) => {
    setCurrentPage(page - 1);
    if (pageSize) {
      setPageSize(pageSize);
    }
  };

  const openPlanningModal = (proposalId: number) => {
    setSelectedProposalId(proposalId);
    setIsPlanningModalVisible(true);
  };

  const handlePlanningModalCancel = () => {
    setIsPlanningModalVisible(false);
    setSelectedProposalId(null);
  };

  const getStatusNameById = (statusId: number) => {
    const status = statuses.find((s: any) => s.id === statusId);
    return status ? status.name : 'Bilinmiyor';
  };

  const getStatusColorById = (statusId: number) => {
    const status = statuses.find((s: any) => s.id === statusId);
    return status ? status.color : '#fdfdfd';
  };

  const renderAllAttributesTooltipContent = (attributes: any) => {
    const mergeDiscounts = (discounts: any) => {
      const merged: any = [];
      discounts.forEach((discount: any) => {
        const existing = merged.find((d: any) => d.price === discount.price);
        if (existing) {
          existing.quantity += discount.quantity;
        } else {
          merged.push({ ...discount });
        }
      });
      return merged;
    };

    return (
      <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
        <Divider style={{ borderColor: '#fff' }} />
        {attributes.map((attribute: any, index: any) => {
          const totalAttributePrice = attribute.discounts
            .filter((discount: any) => discount.type !== 'offering')
            .reduce((total: number, discount: any) => total + discount.price * discount.quantity, 0);

          const serviceFee = attribute.discounts.find((d: any) => d.type === 'offering')?.price ?? 0;
          const serviceFeeDifference = serviceFee > totalAttributePrice ? serviceFee - totalAttributePrice : 0;

          return (
            <div key={index} style={{ marginBottom: '8px' }} className="mt-16px">
              <Text strong style={{ color: '#fff' }}>
                {attribute.attributeName}
              </Text>
              {mergeDiscounts(attribute.discounts).map((discount: any, idx: any) => (
                <p key={idx}>
                  {discount.type === 'offering' ? null : (
                    <>
                      {discount.quantity} adet - {formatToTL(discount.price)}
                    </>
                  )}
                </p>
              ))}
              {serviceFeeDifference > 0 && (
                <p style={{ color: '#f0ad4e', marginTop: '8px' }}>
                  Servis Ücreti = {formatToTL(serviceFeeDifference)}
                </p>
              )}
              {index < attributes.length - 1 && <Divider style={{ borderColor: '#fff' }} />}
            </div>
          );
        })}
      </div>
    );
  };

  // Rapor & Fatura modal
  const handleOpenInvoiceModal = (proposal: any, mode: 'edit' | 'view') => {
    if (!proposal.id) {
      openNotification('error', 'Hata', "Teklif ID'si bulunamadı.");
      return;
    }
    setSelectedProposalId(proposal.id);
    setInvoiceModalData({
      invoiceAmount: proposal.invoiceAmount || '',
      reportUrl: proposal.reportUrl || '',
      financialInfoUrl: proposal.financialInfoUrl || '',
      invoiceDate: proposal.invoiceDate || '',
    });
    setIsInvoiceModalVisible(true);
    setInvoiceModalType(mode);
  };

  // Sütunları oluştururken "proposal.show" iznine göre "İş Ortağı Ad Soyad" alanı
  // (eskiden ADMIN'e gösteriyorduk)
  const hasProposalShow = permissions.includes('proposal.show');

  const columns = [
    // Eğer proposal.show varsa "İş Ortağı Ad Soyad" kolonunu ekle
    ...(hasProposalShow
      ? [
          {
            title: 'İş Ortağı Ad Soyad',
            key: 'accountOwner',
            width: 200,
            render: (_: any, record: any) =>
              record.account
                ? `${record.account.firstName} ${record.account.lastName}`
                : 'Bilgi Yok',
          },
        ]
      : []),
    {
      title: 'Fiyat',
      dataIndex: 'totalPrice',
      key: 'totalPrice',
      width: 150,
      render: (price: number, record: any) =>
        price ? (
          <div className="price-tooltip">
            <span>{formatToTL(price)}</span>
            <Tooltip
              overlayStyle={{ maxWidth: '450px', overflowY: 'auto', maxHeight: '300px' }}
              title={renderAllAttributesTooltipContent(record.attributes)}
            >
              <InfoCircleOutlined style={{ marginLeft: 8, color: '#1890ff' }} />
            </Tooltip>
          </div>
        ) : (
          <span>-</span>
        ),
    },
    {
      title: 'Kontrol Adresi',
      dataIndex: ['address', 'addressLine1'],
      key: 'controlAddress',
      width: 200,
      render: (_: any, record: any) => {
        const addressLine1 = capitalizeWords(record?.address?.addressLine1 || '');
        const city = capitalizeWords(record?.address?.city || '');
        const combinedText = addressLine1 && city ? `${addressLine1}, ${city}` : '-';

        if (combinedText.length > 40) {
          return (
            <Tooltip title={combinedText}>
              <Text>{combinedText.slice(0, 40)}...</Text>
            </Tooltip>
          );
        }
        return <Text>{combinedText || '-'}</Text>;
      },
    },
    {
      title: 'Teklif Tarihi',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 150,
      sorter: true,
      render: (date: string) => (date ? <Text>{formatDate(date)}</Text> : <span>-</span>),
    },
    {
      title: 'Teklif No',
      dataIndex: 'proposalNumber',
      key: 'proposalNumber',
      width: 120,
      render: (proposalNumber: string) =>
        proposalNumber ? <span style={{ color: 'blue' }}>{proposalNumber}</span> : '-',
    },
    {
      title: 'Statü',
      dataIndex: 'statusId',
      key: 'status',
      width: 180,
      render: (statusId: number) => {
        const color = tinycolor(getStatusColorById(statusId));
        const transparentColor = color.setAlpha(0.1).toRgbString();
        const transparentColorBorder = color.setAlpha(0.5).toRgbString();

        return (
          <Tag
            style={{
              color: getStatusColorById(statusId),
              backgroundColor: transparentColor,
              borderColor: transparentColorBorder,
              borderRadius: '16px',
              padding: '5px 10px',
              fontSize: '14px',
              display: 'inline-block',
              minWidth: '100px',
              textAlign: 'center',
              fontWeight: 500,
            }}
          >
            {getStatusNameById(statusId)}
          </Tag>
        );
      },
    },
    {
      title: 'İşlem',
      key: 'action',
      render: (text: string, record: any) => (
        <Dropdown
          overlay={
            record.proposalNumber !== null ? (
              <Menu onClick={({ key }) => handleMenuClick(key, record)}>
                {record.statusId === 11 && <Menu.Item key="sendProposal">Teklifi Gönder</Menu.Item>}
                {record.statusId !== 1 && record.statusId !== null && (
                  <Menu.Item key="download">
                    <ProposalView proposalId={record.id} />
                  </Menu.Item>
                )}
                {record.statusId !== null &&
                  record.statusId !== 2 &&
                  (record.statusId === 3 || record.statusId === 11) && (
                    <Menu.Item key="revise">Revize Et</Menu.Item>
                  )}
                {(record.statusId === 3 || record.statusId === 11) && (
                  <Menu.Item key="applyDiscount">İndirim Uygula</Menu.Item>
                )}
                {record.statusId === 3 && record.statusId === 10 && (
                  <Menu.Item key="uploadDocument">Sözleşme Yükle</Menu.Item>
                )}
                {record.statusId === 6 && (
                  <Menu.Item key="requestPlanningDate">Planlama Talebi Oluştur</Menu.Item>
                )}
                {record.statusId === 4 && (
                  <Menu.Item
                    key="reportView"
                    disabled={
                      record.financialInfoUrl == null ||
                      record.invoiceAmount == null ||
                      record.reportUrl == null
                    }
                    onClick={() => handleOpenInvoiceModal(record, 'view')}
                  >
                    Rapor & Fatura Görüntüle
                  </Menu.Item>
                )}
                {record.statusId === 11 && <Menu.Item key="cancelProposal">Teklifi İptal Et</Menu.Item>}
              </Menu>
            ) : (
              <Menu>
                <Menu.Item key="create" onClick={() => handleNavigateCreate(record?.company?.id)}>
                  Teklif Oluştur
                </Menu.Item>
              </Menu>
            )
          }
        >
          <Button type="default" className="d-button action-button">
            İşlemler
          </Button>
        </Dropdown>
      ),
    },
  ];

  const handleNavigateCreate = (companyId: any = null) => {
    navigate(`/proposals/create?companyId=${companyId}`);
  };

  const handleOfferEditNavigation = (id: number) => {
    navigate(`/proposals/create?proposalId=${id}`);
  };

  const handleCloseInvoiceModal = () => {
    setIsInvoiceModalVisible(false);
  };

  const handleMenuClick = (key: string, record: any) => {
    switch (key) {
      case 'download':
        console.log('Teklif indir:', record);
        break;
      case 'revise':
        handleOfferEditNavigation(record.id);
        break;
      case 'applyDiscount':
        setSelectedProposal(record);
        setIsDiscountModalVisible(true);
        break;
      case 'uploadDocument':
        setSelectedProposalId(record.id);
        setSelectedProposal(record);
        setIsUploadModalVisible(true);
        break;
      case 'cancelProposal':
        handleCancelProposal(record.id);
        break;
      case 'requestPlanningDate':
        setSelectedProposalId(record.id);
        setIsPlanningModalVisible(true);
        break;
      case 'sendProposal':
        handleUpdateStatus(record.id, 3, 1);
        break;
      default:
        break;
    }
  };

  const handleUpdateStatus = async (pid: any, statusId: any, _type: any = 0) => {
    try {
      await ProposalService.update(pid, { statusId });
      if (_type === 1) {
        openNotification('success', 'Başarılı', 'Teklif Gönderildi.');
      } else {
        openNotification('success', 'Başarılı', 'Teklif Güncellendi.');
      }
      fetchProposals(currentPage, pageSize);
    } catch (error) {
      openNotification('error', 'Hata', 'Teklif iptal edilirken bir hata oluştu.');
      console.error(error);
    }
  };

  const handleCancelProposal = async (pid: any) => {
    try {
      await ProposalService.update(pid, { statusId: 2 });
      openNotification('success', 'Başarılı', 'Teklif iptal edildi.');
      fetchProposals(currentPage, pageSize);
    } catch (error) {
      openNotification('error', 'Hata', 'Teklif iptal edilirken bir hata oluştu.');
      console.error(error);
    }
  };

  const handleModalCancel = () => {
    setIsDiscountModalVisible(false);
    setIsUploadModalVisible(false);
    setDiscountedPrice(null);
    setIsPlanningModalVisible(false);
  };

  const handleModalOk = async () => {
    if (selectedProposal && discountedPrice !== null) {
      try {
        await ProposalService.updatePrice(selectedProposal.id, {
          newTotalPrice: discountedPrice,
        });
        openNotification('success', 'Başarılı', 'Fiyat başarıyla güncellendi.');
        fetchProposals(currentPage, pageSize);

        setIsDiscountModalVisible(false);
        setDiscountedPrice(null);
        form.setFieldValue('discountedPrice', null);
      } catch (error) {
        console.error('Fiyat güncellenirken hata oluştu:', error);
        openNotification('error', 'Hata', 'Fiyat güncellenirken hata oluştu.');
      }
    }
  };

  const onFieldsChange = () => {
    const errors = form.getFieldsError();
    const hasError = errors.some((field) => field.errors.length > 0);
    setHasFormError(hasError);
  };

  const handleDiscountChange = (value: any) => {
    if (!value) {
      setDiscountedPrice(selectedProposal.totalPrice);
      return;
    }
    const newDiscountedPrice = selectedProposal.totalPrice - value;
    setDiscountedPrice(newDiscountedPrice);
  };

  return (
    <>
      <Card className="br-30px">
        <Tabs
          activeKey={activeKey}
          onChange={handleTabChange}
          className="custom-tabs"
          tabBarStyle={{ borderBottom: 'none' }}
        >
          <TabPane
            tab={
              <Button
                type={activeKey === '1' ? 'primary' : 'default'}
                className="d-button tab-button"
              >
                Gönderilen Teklifler
              </Button>
            }
            key="1"
          >
            <Table
              scroll={{ x: 'max-content' }}
              columns={columns}
              dataSource={proposals}
              pagination={{
                current: currentPage + 1,
                pageSize: pageSize,
                total: totalItems,
                onChange: (page, newPageSize) => {
                  setCurrentPage(page - 1);
                  setPageSize(newPageSize || 10);
                },
                showSizeChanger: true,
                pageSizeOptions: ['10', '20', '30'],
              }}
              className="offers-table"
            />
          </TabPane>

          <TabPane
            tab={
              <Button
                type={activeKey === '2' ? 'primary' : 'default'}
                className="d-button tab-button"
              >
                Yaklaşan Hizmetler
              </Button>
            }
            key="2"
          >
            <p>Yaklaşan Hizmetler ile ilgili içerik burada yer alacak.</p>
          </TabPane>
        </Tabs>

        {isPlanningModalVisible && (
          <PlanningModal
            proposalId={selectedProposalId}
            isModalVisible={isPlanningModalVisible}
            setIsModalVisible={setIsPlanningModalVisible}
          />
        )}

        {isUploadModalVisible && (
          <DocumentUploadModal
            setIsModalVisible={setIsUploadModalVisible}
            isModalVisible={isUploadModalVisible}
            proposalId={selectedProposalId!}
            isCustomer={true}
          />
        )}

        {/* İndirim Modal */}
        <Modal
          title="İndirim Uygula"
          open={isDiscountModalVisible}
          onCancel={handleModalCancel}
          footer={null}
        >
          {selectedProposal && (
            <>
              <p>İndirimli fiyat teklifinizi hızlı bir şekilde hazırlayın.</p>
              <div style={{ marginBottom: 16 }}>
                <Text strong>Teklif Fiyatı</Text>
                <div style={{ fontSize: '24px', fontWeight: 'bold' }}>
                  {formatToTL(selectedProposal.totalPrice)}
                </div>
              </div>

              <Form layout="vertical" form={form} onFieldsChange={onFieldsChange}>
                <Form.Item
                  label={`İndirim tutarını belirleyin (Kullanılabilir İndirim Tutarı: ${formatToTL(
                    selectedProposal.maxDiscountPrice
                  )})`}
                  name="discountAmount"
                  rules={[
                    {
                      validator: (_, value) => {
                        if (value === undefined || value === null) {
                          return Promise.resolve();
                        }
                        if (
                          typeof value === 'number' &&
                          value >= 0 &&
                          value <= selectedProposal.maxDiscountPrice
                        ) {
                          return Promise.resolve();
                        }
                        if (selectedProposal.maxDiscountPrice === 0) {
                          return Promise.reject(
                            new Error('Daha fazla indirim uygulayamazsınız!')
                          );
                        }
                        return Promise.reject(
                          new Error(
                            `İndirim tutarı 0 ile ${formatToTL(
                              selectedProposal.maxDiscountPrice
                            )} arasında olmalıdır!`
                          )
                        );
                      },
                    },
                  ]}
                >
                  <InputNumber
                    min={0}
                    step={1}
                    precision={0}
                    style={{ width: '100%' }}
                    placeholder="İndirim Tutarı Giriniz"
                    className="d-input d-input-form d-flex align-items-center"
                    onKeyDown={(event) => {
                      if (event.key === ',') {
                        event.preventDefault();
                      }
                    }}
                    onChange={(val) => handleDiscountChange(val)}
                  />
                </Form.Item>

                <div style={{ marginTop: 16 }}>
                  <Text strong>Yeni Teklif Tutarı</Text>
                  <div
                    style={{
                      fontSize: '20px',
                      fontWeight: 'bold',
                      color: '#52c41a',
                      marginTop: '8px',
                    }}
                  >
                    {discountedPrice !== null
                      ? formatToTL(discountedPrice)
                      : formatToTL(selectedProposal.totalPrice)}
                  </div>
                </div>
              </Form>

              <div style={{ marginTop: 24, textAlign: 'center' }}>
                <Button
                  type="primary"
                  className="d-button dark-button width-auto p-20px"
                  disabled={hasFormError || selectedProposal.isTotalPriceSet}
                  onClick={() => {
                    form
                      .validateFields()
                      .then(() => handleModalOk())
                      .catch((err) => {
                        console.log('Form error:', err);
                      });
                  }}
                >
                  İndirimli Teklifi Oluştur
                </Button>
              </div>
            </>
          )}
        </Modal>
      </Card>

      {isInvoiceModalVisible && selectedProposalId && (
        <ReportInvoiceModal
          visible={isInvoiceModalVisible}
          onClose={handleCloseInvoiceModal}
          onSubmit={() => null}
          initialValues={invoiceModalData}
          type={invoiceModalType}
        />
      )}
    </>
  );
};

export default Proposals;