import { http } from "../http-common";

const getAllAgreements = (search?: string) => {
    const params = search ? { params: { search } } : {};
    return http.get<any[]>('/agreements', params);
};

const createAgreement = (data: any) => {
    return http.post<any>('/agreements', data);
};

const updateAgreement = (id: number, data: any) => {
    return http.put<any>(`/agreements/${id}`, data);
};

const deleteAgreement = (id: number) => {
    return http.delete(`/agreements/${id}`);
};

const AgreementService = {
    getAllAgreements,
    createAgreement,
    updateAgreement,
    deleteAgreement,
};

export default AgreementService;