import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Input, Button, Card, Typography, Row } from "antd";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import AgreementService from "../../services/AgreementService";
import { openNotification } from "../../utils";

const { Title } = Typography;

const AgreementCreate: React.FC = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  // ReactQuill editörünün state'i
  const [content, setContent] = useState<string>("");

  // Kaydetme işlemi sırasında loading göstermek için
  const [loading, setLoading] = useState<boolean>(false);

  const onFinish = async (values: any) => {
    setLoading(true);
    try {
      // Sözleşme içeriği (HTML) ReactQuill state'indeki 'content'
      const requestBody = {
        ...values,
        contractHtml: content, 
      };

      await AgreementService.createAgreement(requestBody);
      openNotification("success", "Başarılı", "Sözleşme başarıyla oluşturuldu.");
      navigate("/agreements"); // Oluşturma bitince liste sayfasına yönlendirebilirsiniz
    } catch (error) {
      openNotification("error", "Hata", "Sözleşme oluşturulurken hata oluştu.");
    } finally {
      setLoading(false);
    }
  };

  const onCancel = () => {
    // Geri dönmek isterse
    navigate("/agreements");
  };

  return (
    <Card className="br-30px" style={{ margin: 16 }}>
      <Row justify="space-between" align="middle" style={{ marginBottom: 16 }}>
        <Title style={{ margin: 0 }} level={4}>
          Sözleşme Oluştur
        </Title>
      </Row>

      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        initialValues={{ name: "", contractHtml: "" }}
      >
        <Form.Item
          label="Adı"
          name="name"
          rules={[{ required: true, message: "Lütfen sözleşme adı giriniz." }]}
        >
          <Input placeholder="Sözleşme Adı" />
        </Form.Item>

        <Form.Item 
          label="İçerik" 
          name="contractHtml" 
          // Form tarafından zorunlu kılmak isterseniz:
          // rules={[{ required: true, message: "Lütfen sözleşme içeriğini giriniz." }]}
        >
          {/* ReactQuill HTML editör */}
          <ReactQuill
            theme="snow"
            value={content}
            onChange={setContent}
            style={{ minHeight: 200 }}
          />
        </Form.Item>

        <Form.Item>
          <Row gutter={12}>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              className="d-button dark-button width-auto"
            >
              Kaydet
            </Button>
            <Button onClick={onCancel} disabled={loading}>
              Geri Dön
            </Button>
          </Row>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default AgreementCreate;