import React from 'react';

// 1) Tek tek import
import footerPng from './../../assets/mail/footer.png';
import headerComplatePng from './../../assets/mail/header-complate.png';
import headerPlanlamaPng from './../../assets/mail/header-planlama.png';
import headerWelcomePng from './../../assets/mail/header-welcome.png';
import headerYaklasanPng from './../../assets/mail/header-yaklasan.png';
import iconAddressPng from './../../assets/mail/icon-address.png';
import iconCompanyPng from './../../assets/mail/icon-company.png';
import iconPhonePng from './../../assets/mail/icon-phone.png';
import iconWebsitePng from './../../assets/mail/icon-website.png';
import logoPng from './../../assets/mail/logo.png';
import logoIconPng from './../../assets/mail/logo-icon.png';
import personIconPng from './../../assets/mail/person-icon.png';
import phoneIconPng from './../../assets/mail/phone-icon.png';
import proposalHeaderPng from './../../assets/mail/proposal-header.png';

// 2) Bir diziye koy: { name, src }
const images = [
  { name: 'footer.png', src: footerPng },
  { name: 'header-complate.png', src: headerComplatePng },
  { name: 'header-planlama.png', src: headerPlanlamaPng },
  { name: 'header-welcome.png', src: headerWelcomePng },
  { name: 'header-yaklasan.png', src: headerYaklasanPng },
  { name: 'icon-address.png', src: iconAddressPng },
  { name: 'icon-company.png', src: iconCompanyPng },
  { name: 'icon-phone.png', src: iconPhonePng },
  { name: 'icon-website.png', src: iconWebsitePng },
  { name: 'logo.png', src: logoPng },
  { name: 'logo-icon.png', src: logoIconPng },
  { name: 'person-icon.png', src: personIconPng },
  { name: 'phone-icon.png', src: phoneIconPng },
  { name: 'proposal-header.png', src: proposalHeaderPng },
];

function AssetsGallery() {
  return (
    <div style={{ padding: '20px' }}>
      <h2>Mail Görsel Galerisi (Local Imports)</h2>
      <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px' }}>
        {images.map((item, idx) => (
          <div
            key={idx}
            style={{
              border: '1px solid #ccc',
              borderRadius: '8px',
              padding: '10px',
              textAlign: 'center'
            }}
          >
            <h4 style={{ margin: '0 0 8px 0' }}>{item.name}</h4>
            <img
              src={item.src}
              alt={item.name}
              style={{ maxWidth: '200px', height: 'auto' }}
            />
            <p style={{ fontSize: '12px', marginTop: '4px' }}>
              Bu resmin “import” edilmiş SRC’si.
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default AssetsGallery;