import React, { useEffect, useState, useCallback } from "react";
import {
  Table,
  Card,
  Spin,
  Tag,
  Button,
  Select,
  message,
  Row,
  TableProps,
} from "antd";
import type { ColumnsType, TablePaginationConfig } from "antd/es/table";
import type { SorterResult } from "antd/es/table/interface";

import PartnerReportService from "../../services/PartnerReportService";
import UserService from "../../services/UserService";
import { formatDateString, formatToTL } from "../../utils";
import * as XLSX from "xlsx"; // npm install xlsx
import { saveAs } from "file-saver"; // npm install file-saver
import { DownloadOutlined } from "@ant-design/icons";

interface PartnerReportDto {
  accountId: number;
  firstName: string;
  lastName: string;
  createdAt: string; // ISO string
  status: boolean;
  companyCount: number;
  allProposalsTotalPrice: number;
  statusSuccessCount: number;
  statusSuccessTotalPrice: number;
  statusUnSuccessCount: number;
  statusUnSuccessTotalPrice: number;
  sumIncomeSharePrice: number;
  totalProposalCount: number;
}

interface CustomerItem {
  id: number;
  firstName: string;
  lastName: string;
}

const PartnerReportTable: React.FC = () => {
  const [data, setData] = useState<PartnerReportDto[]>([]);
  const [loading, setLoading] = useState(false);
  const [totalItems, setTotalItems] = useState<number>(0); // Toplam kayıt sayısı

  // Kullanıcı seçimi
  const [userList, setUserList] = useState<CustomerItem[]>([]);
  const [selectedUserId, setSelectedUserId] = useState<number | null>(null);

  // Sıralama için state'ler (default: createdAt, DESC)
  const [sortField, setSortField] = useState<string>("createdAt");
  const [sortDirection, setSortDirection] = useState<string>("DESC");

  // Pagination (antd Table 1-indexli)
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);

  // Kullanıcı listesini çek (arama destekli)
  const fetchUserList = useCallback(async (searchTerm?: string) => {
    try {
      const response: any = await UserService.getAll({
        name: searchTerm || "",
        page: 0,
        size: 20,
        sortField: "createdAt",
        sortDirection: "DESC",
      });
      setUserList(response.data?.content || []);
    } catch (err) {
      console.error("User fetch error:", err);
      message.error("Kullanıcı listesi alınamadı");
    }
  }, []);

  // Rapor verisini sayfalı şekilde çekme
  const fetchPartnerReport = useCallback(async () => {
    try {
      setLoading(true);
      const response = await PartnerReportService.getPartnerReportPaginated({
        accountId: selectedUserId || undefined,
        page: currentPage - 1, // Spring Data 0-based
        size: pageSize,
        sortField,
        sortDirection,
      } as any);
      
      const partnerReportData = response.data;
      setData(partnerReportData?.content || []);
      setTotalItems(
        partnerReportData?.totalElements || (partnerReportData?.content?.length || 0)
      );
    } catch (error) {
      console.error("Partner report fetch error:", error);
      message.error("Rapor verisi alınamadı");
    } finally {
      setLoading(false);
    }
  }, [selectedUserId, currentPage, pageSize, sortField, sortDirection]);

  useEffect(() => {
    fetchUserList();
    fetchPartnerReport();
  }, [fetchUserList, fetchPartnerReport]);

  const handleUserChange = (value: number | null) => {
    setSelectedUserId(value);
    setCurrentPage(1);
  };

  // Excel export (veri, tablo sütun başlıkları ile uyumlu şekilde aktarılıyor)
  const handleExportExcel = async () => {
    try {
      setLoading(true);
      const response = await PartnerReportService.getPartnerReportAll({
        accountId: selectedUserId || undefined,
      });
      const rawData: PartnerReportDto[] = response.data || [];

      // Excel'de görünecek sütun başlıkları ve veriler
      const exportData = rawData.map((item) => ({
        "İş Ortağı": `${item.firstName} ${item.lastName}`,
        "Kayıt Tarihi": formatDateString(item.createdAt),
        "Firma Sayısı": item.companyCount,
        "Durum": item.status ? "Aktif" : "Pasif",
        "Toplam Teklif Sayısı": item.totalProposalCount,
        "Toplam Teklif Tutarı": formatToTL(item.allProposalsTotalPrice),
        "Sözleşmeye Dönen Teklif Sayısı": item.statusSuccessCount,
        "Sözleşmeye Dönen Teklif Tutarı": formatToTL(item.statusSuccessTotalPrice),
        "Olumsuz Teklif Sayısı": item.statusUnSuccessCount,
        "Olumsuz Teklif Tutarı": formatToTL(item.statusUnSuccessTotalPrice),
        "İş Ortağı Kazancı": formatToTL(item.sumIncomeSharePrice),
      }));

      // Header sıralamasını belirleyerek Excel sayfasını oluşturuyoruz
      const worksheet = XLSX.utils.json_to_sheet(exportData, {
        header: [
          "İş Ortağı",
          "Kayıt Tarihi",
          "Firma Sayısı",
          "Durum",
          "Toplam Teklif Sayısı",
          "Toplam Teklif Tutarı",
          "Sözleşmeye Dönen Teklif Sayısı",
          "Sözleşmeye Dönen Teklif Tutarı",
          "Olumsuz Teklif Sayısı",
          "Olumsuz Teklif Tutarı",
          "İş Ortağı Kazancı",
        ],
      });
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "PartnerReport");
      const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
      const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
      saveAs(blob, "partner-report.xlsx");
    } catch (err) {
      console.error("Excel export error:", err);
      message.error("Excel indirirken hata oluştu");
    } finally {
      setLoading(false);
    }
  };

  /**
   * Tablo sütunları
   * - 'Durum' sütunu için sorter uygulanmıyor.
   * - Diğer sütunlar için sorter uygulanıyor.
   */
  const columns: ColumnsType<PartnerReportDto> = [
    {
      title: "İş Ortağı",
      dataIndex: "firstName",
      key: "firstName",
      sorter: true,
      render: (_, record) => `${record.firstName} ${record.lastName}`,
    },
    {
      title: "Kayıt Tarihi",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: true,
      render: (value: string) => formatDateString(value),
    },
    {
      title: "Firma Sayısı",
      dataIndex: "companyCount",
      key: "companyCount",
      sorter: true,
    },
    {
      title: "Durum",
      key: "status",
      sorter: false,
      render: (_, record) =>
        record.status ? (
          <Tag color="green">Aktif</Tag>
        ) : (
          <Tag color="red">Pasif</Tag>
        ),
    },
    {
      title: "Toplam Teklif Sayısı",
      dataIndex: "totalProposalCount",
      key: "totalProposalCount",
      sorter: true,
    },
    {
      title: "Toplam Teklif Tutarı",
      dataIndex: "allProposalsTotalPrice",
      key: "allProposalsTotalPrice",
      sorter: true,
      render: (price: number) => formatToTL(price),
    },
    {
      title: "Sözleşmeye Dönen Teklif Sayısı",
      dataIndex: "statusSuccessCount",
      key: "statusSuccessCount",
      sorter: true,
    },
    {
      title: "Sözleşmeye Dönen Teklif Tutarı",
      dataIndex: "statusSuccessTotalPrice",
      key: "statusSuccessTotalPrice",
      sorter: true,
      render: (price: number) => formatToTL(price),
    },
    {
      title: "Olumsuz Teklif Sayısı",
      dataIndex: "statusUnSuccessCount",
      key: "statusUnSuccessCount",
      sorter: true,
    },
    {
      title: "Olumsuz Teklif Tutarı",
      dataIndex: "statusUnSuccessTotalPrice",
      key: "statusUnSuccessTotalPrice",
      sorter: true,
      render: (price: number) => formatToTL(price),
    },
    {
      title: "İş Ortağı Kazancı",
      dataIndex: "sumIncomeSharePrice",
      key: "sumIncomeSharePrice",
      sorter: true,
      render: (price: number) => formatToTL(price),
    },
  ];

  /**
   * Tablo değiştiğinde (pagination veya sıralama) çalışacak fonksiyon.
   * Kullanıcı bir sütun başlığına tıkladığında sortField ve sortDirection güncelleniyor.
   */
  const handleTableChange: TableProps<PartnerReportDto>["onChange"] = (
    pagination: TablePaginationConfig,
    _filters: any,
    sorter: SorterResult<PartnerReportDto> | SorterResult<PartnerReportDto>[]
  ) => {
    if (pagination.current) {
      setCurrentPage(pagination.current);
    }
    if (pagination.pageSize) {
      setPageSize(pagination.pageSize);
    }
    const singleSorter = Array.isArray(sorter) ? sorter[0] : sorter;
    if (singleSorter && singleSorter.field && singleSorter.order) {
      const direction = singleSorter.order === "ascend" ? "ASC" : "DESC";
      setSortField(singleSorter.field as string);
      setSortDirection(direction);
    } else {
      setSortField("createdAt");
      setSortDirection("DESC");
    }
  };

  // Kullanıcı arama
  const handleSearchUser = async (inputValue: string) => {
    await fetchUserList(inputValue);
  };

  useEffect(() => {
    fetchPartnerReport();
  }, [
    selectedUserId,
    currentPage,
    pageSize,
    sortField,
    sortDirection,
    fetchPartnerReport,
  ]);

  return (
    <Card style={{ marginBottom: "120px" }}>
      <Spin spinning={loading}>
        <Row style={{ marginBottom: 16 }} justify={"end"}>
          <Select<number | null>
            showSearch
            allowClear
            placeholder="İş Ortağı Seçiniz"
            style={{ width: 250, marginRight: 10 }}
            className="d-input-filter"
            onSearch={handleSearchUser}
            onChange={(val) => handleUserChange(val || null)}
            filterOption={false}
          >
            {userList.map((user) => (
              <Select.Option key={user.id} value={user.id}>
                {user.firstName} {user.lastName}
              </Select.Option>
            ))}
          </Select>

          <Button
            type="default"
            onClick={handleExportExcel}
            icon={<DownloadOutlined />}
            className="width-auto d-button"
          />
        </Row>

        <Table<PartnerReportDto>
          columns={columns}
          dataSource={data}
          rowKey="accountId"
          pagination={{
            current: currentPage,
            pageSize: pageSize,
            total: totalItems,
            showSizeChanger: true,
          }}
          onChange={handleTableChange}
        />
      </Spin>
    </Card>
  );
};

export default PartnerReportTable;