import React, { useEffect, useState } from 'react'
import Upload, { RcFile } from "antd/es/upload";
import { LoadingOutlined, UploadOutlined, PlusOutlined } from "@ant-design/icons";
import { openNotification } from '../../utils';
import ImageService from '../../services/ImageService';
import { Avatar } from 'antd';
import './ImageUpload.scss';

const ImageUpload: React.FC<any> = ({ setImageId, imageId, type, disabled = false }) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [imagePreview, setImagePreview] = useState<string | null>(null);
    const [hovered, setHovered] = useState(false);
    const firstName = localStorage.getItem('firstName');
    const lastName = localStorage.getItem('lastName');
    useEffect(() => {
        const cachedImage = localStorage.getItem('profilePhotoBase64');
        if (imageId) {
            if (cachedImage && type === 'profile' && cachedImage == imagePreview) {
                setImagePreview(cachedImage);
            } else {
                const fetchImage = async () => {
                    try {
                        const imageResponse: any = await ImageService.getImage(imageId);
                        const imageUrl = imageResponse?.data?.preSignUrl;
                        if (type === 'profile') {
                            localStorage.setItem('profilePhotoBase64', imageUrl);
                            setImagePreview(imageUrl);
                        } else {
                            setImagePreview(imageUrl);
                        }

                    } catch (error) {
                        console.error("Resim yüklenirken hata oluştu:", error);
                    }
                };
                fetchImage();
            }
        }
    }, [imageId, type]);


    const handleImageChange = async (info: any) => {
        const file = info.file.originFileObj as RcFile;
        const reader = new FileReader();
        setLoading(true);

        reader.onload = () => {
            setImagePreview(reader.result as string);
        };


        try {
            reader.readAsDataURL(file);
            const formData = new FormData();
            formData.append('image', file);

            const fileNameWithoutExtension = file.name.split('.').slice(0, -1).join('.');
            const uniqueFileName = `${fileNameWithoutExtension}_${Date.now()}`;

            const response = await ImageService.postImage(formData, `?code=${uniqueFileName}`);
            setImageId(response.data.id);
            setLoading(false);


        } catch (error) {
            console.error("Resim yüklenirken hata oluştu:", error);
            setLoading(false);

        }
    };

    const beforeUpload = (file: RcFile) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            openNotification('error', 'Hata', 'Yalnızca JPG/PNG dosyaları yüklenebilir.');
        }
        const isLt2M = file.size / 1024 / 1024 < 1;
        if (!isLt2M) {
            openNotification('error', 'Hata', 'Resim 1 MB boyutunu aşamaz.');
        }
        return isJpgOrPng && isLt2M;
    };
    const uploadButton = (
        <button className="border-none bg-gray-2" type="button">
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div className="mt-8px">Yükle</div>
        </button>
    );
    return (
        <>
            <Upload
                disabled={disabled}
                listType="picture-circle"
                name="logo"
                className="upload-list-inline avatar-uploader width-100px height-100px bg-gray borderRadius-50"
                customRequest={() => { }} // Ant Design'ın default yükleme fonksiyonunu devre dışı bırakır
                onChange={handleImageChange}
                showUploadList={false}
                beforeUpload={beforeUpload}
            >
                {imagePreview ? (
                    <div
                        onMouseEnter={() => setHovered(true)}
                        onMouseLeave={() => setHovered(false)}
                        style={{ position: 'relative', display: 'flex', cursor: 'pointer' }}
                    >
                        <img
                            src={imagePreview}
                            alt="Logo Preview"
                            className="borderRadius-50 width-100px height-100px object-fit-cover bg-gray"
                        />
                        {hovered && (
                            <div className='profile-photo-hover-icon'>
                                <UploadOutlined style={{ fontSize: '24px', color: '#fff' }} />
                            </div>
                        )}
                    </div>
                ) : type == 'profile' ?
                    <Avatar
                        size={100}
                        src={imagePreview}
                        style={{ border: '1px solid #d9d9d9' }}
                    >
                        {firstName ? firstName.charAt(0).toUpperCase() : ''}
                    </Avatar> :
                    uploadButton}
            </Upload>

        </>
    )
}

export default ImageUpload