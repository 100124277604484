import React, { useEffect, useState } from 'react';
import { Button, Table, Popconfirm, Modal, Typography, Input, Row, Card } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import DiscountRateService from '../../services/DiscountRateService';
import { formatToTL, openNotification } from '../../utils';
import DiscountRateModal from '../../components/DiscountRate/DiscountRateModal';
import TrashIcon from '../../assets/icons/trash.svg';
import PenIcon from '../../assets/icons/pen.svg';
import { SearchOutlined } from '@ant-design/icons';

interface DiscountRate {
    id: number;
    maxPrice: number;
    discountRate: number;
    description: string;
}

const DiscountRate: React.FC = () => {
    const [data, setData] = useState<DiscountRate[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const [selectedDiscountRate, setSelectedDiscountRate] = useState<DiscountRate | null>(null);
    const [searchTerm, setSearchTerm] = useState<string>(''); // Store search input value
    const { Title } = Typography;
    let debounceTimer: any; // Timer for debouncing the search input

    useEffect(() => {
        fetchDiscountRates(); // Fetch data on initial load
    }, []);

    const fetchDiscountRates = async (search?: string) => {
        setLoading(true);
        try {
            const response = await DiscountRateService.getAll(search);
            setData(response.data);
        } catch (error) {
            console.error('Veriler çekilirken hata:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleDelete = async (id: number) => {
        try {
            await DiscountRateService.remove(id);
            openNotification('success', 'Başarılı', 'İndirim oranı başarıyla silindi');
            fetchDiscountRates(); // Refresh data after deletion
        } catch (error) {
            console.error('Silinirken hata:', error);
        }
    };

    const handleEdit = (record: DiscountRate) => {
        setSelectedDiscountRate(record);
        setModalVisible(true);
    };

    const handleCreate = () => {
        setSelectedDiscountRate(null);
        setModalVisible(true);
    };

    const handleModalClose = () => {
        setModalVisible(false);
        setSelectedDiscountRate(null);
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setSearchTerm(value);

        // Clear previous debounce timer
        if (debounceTimer) {
            clearTimeout(debounceTimer);
        }

        // Set new debounce timer to trigger search after 300ms
        debounceTimer = setTimeout(() => {
            fetchDiscountRates(value); // Fetch data based on search term
        }, 300);
    };

    const columns: ColumnsType<DiscountRate> = [
        {
            title: 'Fiyat Aralığı',
            dataIndex: 'maxPrice',
            key: 'maxPrice',
            render: (maxPrice: number) => `${formatToTL(maxPrice)} üzeri  `,
        },
        {
            title: 'İndirim Oranı',
            dataIndex: 'discountRate',
            key: 'discountRate',
            render: (discountRate: number) => `%${discountRate}`,
        },
        {
            title: 'Açıklama',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Hizmet Grubu',
            dataIndex: 'offeringName',
            key: 'offeringName',
        },
        {
            title: 'İşlem',
            key: 'action',
            width: 140,
            render: (_, record) => (
                <div >
                    <Button
                        shape="circle"
                        icon={<img src={PenIcon} alt="edit" className="icon" />}
                        onClick={() => handleEdit(record)}
                        className='icon-button mr-10px'
                    />
                    <Popconfirm
                        title="Bu indirim oranını silmek istediğinize emin misiniz?"
                        onConfirm={() => handleDelete(record.id)}
                        okText="Evet"
                        cancelText="Hayır"
                    >
                        <Button
                            shape="circle"
                            icon={<img src={TrashIcon} alt="delete" className="icon" />}
                            className='icon-button'
                        />
                    </Popconfirm>
                </div>
            ),
        },
    ];

    return (
        <Card className='br-30px'>
            <Row justify={'space-between'} align={'middle'} className='mb-16px'>
                <Title style={{ margin: 0 }} level={4}>İndirim Oranları Listesi</Title>
                <Row>
                    <Input
                        placeholder="Ara"
                        prefix={<SearchOutlined style={{ color: '#b8b9bc' }} />}
                        className="d-input d-input-form mr-16px d-input-filter"
                        style={{ width: 200, marginRight: 16 }}
                        onChange={handleSearchChange}
                    />
                    <Button
                        type="primary"
                        onClick={handleCreate}
                        className='d-button width-auto dark-button'
                    >
                        Yeni İndirim Ekle
                    </Button>
                </Row>
            </Row>
            <Table
                columns={columns}
                dataSource={data}
                rowKey="id"
                loading={loading}
                pagination={false}
            />
            <DiscountRateModal
                visible={modalVisible}
                onClose={handleModalClose}
                selectedDiscountRate={selectedDiscountRate}
                fetchDiscountRates={fetchDiscountRates}
            />
        </Card>
    );
};

export default DiscountRate;