import React, { useState, useEffect, useCallback } from 'react';
import {
  Button,
  Row,
  Table,
  Typography,
  Popconfirm,
  Tag,
  Card,
  Input,
  Modal,
  Select,
} from 'antd';
import {
  PoweroffOutlined,
  EditOutlined,
  SearchOutlined,
  UserSwitchOutlined,
} from '@ant-design/icons';
import type { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { debounce } from 'lodash';
import UserService from '../../services/UserService';
import RoleService from '../../services/RoleService'; // ROLLERİ BURADAN GETİRECEĞİZ
import { openNotification } from '../../utils';       // openNotification fonksiyonu

const { Title } = Typography;

export const UserList = () => {
  const [users, setUsers] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState<string>('');

  // Sort/sıralama
  const [sortField, setSortField] = useState<string>('createdAt');
  const [sortDirection, setSortDirection] = useState<string>('DESC');

  // Sayfalama
  const [pagination, setPagination] = useState<any>({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  // Router
  const navigate = useNavigate();

  // Rol değiştirme modal state'leri
  const [roleChangeModalVisible, setRoleChangeModalVisible] = useState(false);
  const [selectedUser, setSelectedUser] = useState<any>(null);
  const [newRole, setNewRole] = useState<string>('');

  // Sunucudan gelen tüm roller:
  const [allRoles, setAllRoles] = useState<any[]>([]);

  // **Giriş yapan kullanıcının** rolleri (Admin mi diye bakacağız)
  const [loggedInUserRoles, setLoggedInUserRoles] = useState<any[]>([]);

  /**
   * Roller API'den çekilir ve state'e kaydedilir.
   * name === 'CUSTOMER' olan rol, ekranda "İş Ortağı" olarak gösterilecek.
   */
  const fetchRoles = async () => {
    try {
      const res = await RoleService.getAll(); 
      // Örnek response: res.data = [{ id:1, name:'CUSTOMER'}, {id:2, name:'ADMIN'}, ...]
      setAllRoles(res.data || []);
    } catch (error) {
      console.error(error);
      openNotification('error', 'Hata', 'Roller yüklenirken hata oluştu.');
    }
  };

  // Giriş yapan kullanıcının rollerini localStorage'dan oku
  const loadLoggedInUserRoles = () => {
    const storedRoles = localStorage.getItem('roles');
    if (storedRoles) {
      try {
        const parsed = JSON.parse(storedRoles);
        setLoggedInUserRoles(parsed);
      } catch (err) {
        console.error('Giriş yapan kullanıcının rolleri parse edilirken hata:', err);
      }
    }
  };

  /**
   * Tablodaki kullanıcıları çekmek
   */
  const getUsers = useCallback(
    (
      search?: string,
      page = 1,
      pageSize = 10,
      sField = 'createdAt',
      sDirection = 'DESC'
    ) => {
      setLoading(true);
      UserService.getAll({
        name: search,
        page: page - 1, // Spring vb. backend 0-based
        size: pageSize,
        sortField: sField,
        sortDirection: sDirection,
      })
        .then((response: any) => {
          setUsers(response.data.content);
          setPagination((prev: any) => ({
            ...prev,
            current: page,
            pageSize,
            total: response.data.totalElements,
          }));
          setLoading(false);
        })
        .catch((e: any) => {
          console.error(e);
          setLoading(false);
          openNotification('error', 'Hata', 'Kullanıcılar yüklenirken hata oluştu.');
        });
    },
    []
  );

  // İlk yüklemelerde kullanıcılar + roller çekilir + giriş yapan userRoller
  useEffect(() => {
    loadLoggedInUserRoles();
    getUsers(searchTerm, pagination.current, pagination.pageSize, sortField, sortDirection);
    fetchRoles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination.current, pagination.pageSize, searchTerm, sortField, sortDirection]);

  // Kullanıcı statüsünü (aktif/pasif) değiştir
  const toggleUserStatus = async (id: number, isActive: boolean) => {
    try {
      await UserService.toggleStatus(id, !isActive);
      openNotification('success', 'Başarılı', `Kullanıcı ${!isActive ? 'aktif' : 'pasif'} hale getirildi.`);
      getUsers(searchTerm, pagination.current, pagination.pageSize, sortField, sortDirection);
    } catch (error) {
      console.error(error);
      openNotification('error', 'Hata', 'İşlem sırasında bir hata oluştu.');
    }
  };

  // Aramayı debounce ile yönet
  const debouncedSearch = useCallback(
    debounce((value: string) => {
      setPagination((prev: any) => ({ ...prev, current: 1 }));
      setSearchTerm(value);
    }, 300),
    []
  );

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    debouncedSearch(event.target.value);
  };

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  // Tablo sayfa/sort değişimlerini yönet
  const handleTableChange = (
    paginationConfig: TablePaginationConfig,
    _filters: any,
    sorter: any | any[]
  ) => {
    let sField = sortField;
    let sDirection = sortDirection;

    if (!Array.isArray(sorter) && sorter.field) {
      sField = sorter.field.toString();
      sDirection = sorter.order === 'ascend' ? 'ASC' : 'DESC';
    }

    setSortField(sField);
    setSortDirection(sDirection);

    setPagination({
      ...pagination,
      current: paginationConfig.current,
      pageSize: paginationConfig.pageSize || 10,
    });
  };

  // Kullanıcı detay sayfasına git
  const handleDetail = (id: string) => {
    navigate(`/users/${id}`);
  };

  // "Rolü Değiştir" butonu
  const openRoleChangeModal = (user: any) => {
    setSelectedUser(user);
    if (user.roles && user.roles.length > 0) {
      setNewRole(user.roles[0].name);
    } else {
      setNewRole('CUSTOMER'); 
    }
    setRoleChangeModalVisible(true);
  };

  // "Rolü Değiştir" Kaydet
  const handleRoleChangeSave = async () => {
    if (!selectedUser) return;

    try {
      // allRoles içinden name=newRole olanı bulalım
      const selectedRoleObj = allRoles.find((r: any) => r.name === newRole);
      if (!selectedRoleObj) {
        openNotification('error', 'Hata', 'Seçilen rol geçersiz.');
        return;
      }
      // roleId bu objeden gelir
      const roleId = selectedRoleObj.id;

      await UserService.updateRole(selectedUser.id, { roleId });
      openNotification('success', 'Başarılı', 'Kullanıcının rolü başarıyla güncellendi.');
      setRoleChangeModalVisible(false);
      setSelectedUser(null);

      // Listeyi yenile
      getUsers(searchTerm, pagination.current, pagination.pageSize, sortField, sortDirection);
    } catch (error) {
      console.error(error);
      openNotification('error', 'Hata', 'Rol güncellenirken bir hata oluştu.');
    }
  };

  const handleRoleChangeCancel = () => {
    setRoleChangeModalVisible(false);
    setSelectedUser(null);
  };

  // "CUSTOMER" => "İş Ortağı", aksi halde role.name'i label
  const dynamicRoleOptions = allRoles.map((role) => ({
    value: role.name,
    label: role.name === 'CUSTOMER' ? 'İş Ortağı' : role.name,
  }));

  // **Şu anki kullanıcı Admin mi?** Kontrolü
  const isCurrentUserAdmin = loggedInUserRoles.some((r) => r.name === 'ADMIN');

  // Tablo kolonları
  const columns: ColumnsType<any> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Ad Soyad',
      dataIndex: 'firstName',
      key: 'firstName',
      sorter: true,
      render: (value, record) => `${record.firstName} ${record.lastName}`,
    },
    {
      title: 'E-posta',
      dataIndex: 'email',
      key: 'email',
      sorter: true,
    },
    {
      title: 'Telefon',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
    },
    {
      title: 'Rol',
      key: 'roles',
      render: (_, record) => {
        // record.roles -> [{id:1, name:'CUSTOMER'}, ...]
        if (!record.roles || record.roles.length === 0) return '—';
        const roleNames = record.roles.map((r: any) => {
          if (r.name === 'CUSTOMER') return 'İş Ortağı';
          return r.name;
        });
        return roleNames.join(', ');
      },
    },
    {
      title: 'Katılma Tarihi',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: true,
      render: (createdAt: string) => moment(createdAt).format('DD/MM/YYYY HH:mm'),
    },
    {
      title: 'Statü',
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      render: (value, record) => (
        <Tag color={record.status ? 'green' : 'red'}>
          {record.status ? 'Aktif' : 'Pasif'}
        </Tag>
      ),
    },
    {
      title: 'Son Giriş Zamanı',
      dataIndex: 'lastSuccessfulLogin',
      key: 'lastSuccessfulLogin',
      sorter: true,
      render: (val: string) => {
        if (!val) {
          return <Tag color="default">Henüz giriş yapmamış</Tag>;
        }
        return <Tag color="default">{moment(val).fromNow()}</Tag>;
      },
    },
    {
      title: 'İşlem',
      key: 'action',
      render: (_, record) => (
        <div style={{ display: 'flex', gap: 8 }}>
          <Button
            shape="circle"
            icon={<EditOutlined />}
            className="icon-button"
            onClick={() => handleDetail(record.id)}
          />
          <Popconfirm
            title={`Bu kullanıcıyı ${
              record.status ? 'pasif' : 'aktif'
            } hale getirmek istiyor musunuz?`}
            onConfirm={() => toggleUserStatus(record.id, record.status)}
            okText="Evet"
            cancelText="Hayır"
          >
            <Button
              shape="circle"
              className="icon-button"
              icon={
                record.status ? (
                  <PoweroffOutlined style={{ color: 'green' }} />
                ) : (
                  <PoweroffOutlined style={{ color: 'red' }} />
                )
              }
            />
          </Popconfirm>

          {/* YALNIZCA ADMIN GÖRÜYOR => "Rolü Değiştir" butonu */}
          {isCurrentUserAdmin && (
            <Button
              shape="circle"
              className="icon-button"
              icon={<UserSwitchOutlined />}
              onClick={() => openRoleChangeModal(record)}
            />
          )}
        </div>
      ),
    },
  ];

  return (
    <Card className="br-30px mb-120px">
      <Row className="mb-20px" justify="space-between" align="middle">
        <Title level={4}>Kullanıcı Listesi</Title>
        <Input
          placeholder="Ara"
          prefix={<SearchOutlined style={{ color: '#b8b9bc' }} />}
          className="d-input d-input-form d-input-filter"
          style={{ width: 200 }}
          onChange={handleSearchChange}
        />
      </Row>
      <Table
        scroll={{ x: 768 }}
        loading={loading}
        columns={columns}
        dataSource={users}
        pagination={{
          current: pagination.current,
          pageSize: pagination.pageSize,
          total: pagination.total,
          showSizeChanger: true,
        }}
        onChange={handleTableChange}
        rowKey="id"
      />

      <Modal
        title="Rolü Değiştir"
        visible={roleChangeModalVisible}
        onOk={handleRoleChangeSave}
        onCancel={handleRoleChangeCancel}
        okText="Kaydet"
        cancelText="İptal"
      >
        {selectedUser && (
          <div>
            <p>
              <strong>Kullanıcı:</strong> {selectedUser.firstName} {selectedUser.lastName}
            </p>
            <Select
              style={{ width: '100%' }}
              value={newRole}
              onChange={(val) => setNewRole(val)}
              options={dynamicRoleOptions}
            />
          </div>
        )}
      </Modal>
    </Card>
  );
};