import React, { useState } from 'react';
import { Tabs, Button, Table, Tag, Space, Card } from 'antd';
import { Link } from 'react-router-dom';
import Statuses from '../../modules/Status/Statuses';
import { RoleList } from '../Role/RoleList';
import DiscountRate from '../DiscountRate/DiscountRate';
import ToolList from '../../components/Tools/ToolList';
import ParameterList from '../../components/Parameters/ParameterList';
import OfferingList from '../../components/Offerings/OfferingList';
import AgreementList from '../../components/Agreements/AgreementList';
import AnnouncementList from '../../components/Announcements/AnnouncementList';

const { TabPane } = Tabs;

const Settings: React.FC = () => {
    const [activeKey, setActiveKey] = useState('1');

    const handleTabChange = (key: string) => {
        setActiveKey(key);
    };


    return (
            <Tabs activeKey={activeKey} onChange={handleTabChange} className="custom-tabs mb-120px" tabBarStyle={{ borderBottom: 'none' }}>
                <TabPane
                    tab={
                        <Button type={activeKey === '1' ? 'primary' : 'default'} className='d-button tab-button'>Statüler</Button>
                    }
                    key="1"
                >
                    <Statuses />
                </TabPane>
                <TabPane
                    tab={
                        <Button type={activeKey === '2' ? 'primary' : 'default'} className='d-button tab-button'>Roller</Button>
                    }
                    key="2"
                >

                    <RoleList />
                </TabPane>
                <TabPane
                    tab={
                        <Button type={activeKey === '3' ? 'primary' : 'default'} className='d-button tab-button'>İndirim Oranları</Button>
                    }
                    key="3"
                >

                    <DiscountRate />
                </TabPane>
                <TabPane
                    tab={
                        <Button type={activeKey === '4' ? 'primary' : 'default'} className='d-button tab-button'>Bileşenler</Button>
                    }
                    key="4"
                >

                    <ToolList />
                </TabPane>
                <TabPane
                    tab={
                        <Button type={activeKey === '5' ? 'primary' : 'default'} className='d-button tab-button'>Parametre Yönetimi</Button>
                    }
                    key="5"
                >
                    <ParameterList />
                </TabPane>

                <TabPane
                    tab={
                        <Button type={activeKey === '6' ? 'primary' : 'default'} className='d-button tab-button'>Hizmet Grubu Yönetimi</Button>
                    }
                    key="6"
                >
                    <OfferingList />
                </TabPane>
                <TabPane
                    tab={
                        <Button type={activeKey === '7' ? 'primary' : 'default'} className='d-button tab-button'>Sözleşme Yönetimi</Button>
                    }
                    key="7"
                >
                    <AgreementList />
                </TabPane>
                <TabPane
                    tab={
                        <Button type={activeKey === '8' ? 'primary' : 'default'} className='d-button tab-button'>Duyuru Yönetimi</Button>
                    }
                    key="8"
                >
                    <AnnouncementList />
                </TabPane>
            </Tabs>
    );
};

export default Settings;