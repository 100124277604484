import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Typography,
  Layout,
  Space,
  Divider
} from 'antd';
import { EyeTwoTone, EyeInvisibleOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { clearStorage, openNotification } from '../../utils';
import './auth.scss';
import UserService from '../../services/UserService';
import CookieConsentModal from './CookieConsentModal';
import ImageService from '../../services/ImageService';
import ReCAPTCHA from 'react-google-recaptcha';
import Logo from '../../assets/auth/kontrolxlogo.svg';
import AgreementService from '../../services/AgreementService';

const { Title } = Typography;
const { Content } = Layout;

const Login = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [isCookieModal, setIsCookieModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Başarısız deneme sayacı
  const [loginAttempt, setLoginAttempt] = useState<number>(0);
  // reCAPTCHA durumu
  const [captchaValid, setCaptchaValid] = useState<boolean>(false);
  // reCAPTCHA referansı
  const recaptchaRef = React.useRef<ReCAPTCHA>(null);

  // reCAPTCHA site key
  const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

  useEffect(() => {
    const analyticsEnabled = localStorage.getItem('analyticsEnabled');
    const adsEnabled = localStorage.getItem('adsEnabled');
    if (!analyticsEnabled && !adsEnabled) {
      setIsCookieModal(true);
    }
  }, []);

  // reCAPTCHA callback
  const onRecaptchaChange = (token: string | null) => {
    setCaptchaValid(!!token);
  };

  const onFinish = async (values: { email: string; password: string; }) => {
    // Çok fazla hatalı deneme varsa ve reCAPTCHA henüz geçilmediyse engelle
    if (loginAttempt >= 3 && !captchaValid) {
      openNotification('warning', 'Doğrulamayı Onayla', 'Lütfen robot olmadığınızı doğrulayın.');
      return;
    }

    setIsLoading(true);

    try {
      let recaptchaToken;
      if (loginAttempt >= 3) {
        recaptchaToken = await recaptchaRef.current?.getValue();
      }

      const userData = {
        email: values.email,
        password: values.password,
        recaptchaToken: recaptchaToken
      };

      const apiUrl = process.env.REACT_APP_API_BASE_URL + '/auth/login';
      const response = await axios.post(apiUrl, userData);

      localStorage.setItem('accessToken', response?.data?.accessToken);
      localStorage.setItem('accessTokenExpiredAt', response?.data?.accessTokenExpiredAt);
      localStorage.setItem('refreshToken', response?.data?.refreshToken);
      localStorage.setItem('refreshTokenExpiredAt', response?.data?.refreshTokenExpiredAt);

      await userGet();
      setLoginAttempt(0);
      if (recaptchaRef.current) {
        recaptchaRef.current.reset();
      }
      setCaptchaValid(false);

    } catch (error: any) {
      console.error(error);
      let errorMessage = 'Giriş Yapılamadı.';

      if (error.response?.data) {
        const responseData = error.response.data;
        if (responseData.errorCode === "email-not-verified") {
          openNotification('warning', 'E-posta Onayı Gerekli',
            'E-posta adresiniz onaylanmamış. Lütfen e-posta onayı için yönlendiriliyorsunuz.'
          );
          setTimeout(() => {
            navigate(`/email-verify-check?email=${encodeURIComponent(values.email)}`);
          }, 3000);
          return;
        } else if (responseData.errorCode === "invalid-username-or-password") {
          errorMessage = 'Belirtilen E-Posta adresi veya şifre geçersiz.';
        }
      }

      openNotification('error', 'Başarısız', errorMessage);
      setLoginAttempt(prev => prev + 1);
      setCaptchaValid(false);
      if (recaptchaRef.current) {
        recaptchaRef.current.reset();
      }

    } finally {
      setIsLoading(false);
    }
  };

  const onProvider = async (values: { provider: string; }) => {
    console.log(values.provider);
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/oauth2/${values.provider}/redirect-uri`);
      const authorizationUrl = response.data.authorizationUrl;
      window.location.href = authorizationUrl;
    } catch (error) {
      console.log(error);
      openNotification('error', 'Başarısız', 'OAuth isteği başarısız.');
    }
  };

  const userGet = () => {
    const token = localStorage.getItem('accessToken');
    if (token) {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const payload = JSON.parse(window.atob(base64));
      const authorities = payload.authorities || [];
      const isAdmin = authorities.some((auth: any) => auth.authority === 'ROLE_ADMIN');
      if (isAdmin) {
        UserService.getMeUser()
          .then((response: any) => {
            processUserResponse(response);
          })
          .catch((e: Error) => {
            openNotification('error', 'Başarısız', 'Giriş yapılamadı.');
            clearStorage();
            console.log(e);
          });
      } else {
        UserService.getMe()
          .then((response: any) => {
            processUserResponse(response);
          })
          .catch((e: Error) => {
            openNotification('error', 'Başarısız', 'Giriş yapılamadı.');
            clearStorage();
            console.log(e);
          });
      }
    } else {
      openNotification('error', 'Başarısız', 'Token bulunamadı.');
      clearStorage();
    }
  };

  const processUserResponse = (response: any) => {
    localStorage.setItem('firstName', response.data.firstName);
    localStorage.setItem('lastName', response.data.lastName);
    localStorage.setItem('email', response.data.email);
    localStorage.setItem('phoneNumber', response.data.phoneNumber);
    localStorage.setItem('id', response.data.id);
    localStorage.setItem('profilePhoto', response.data.profilePhoto);

    const getImage = async () => {
      if (response.data.profilePhoto) {
        try {
          const imageResponse: any = await ImageService.getImage(response.data.profilePhoto);
          const imageUrl = imageResponse?.data?.preSignUrl;
          localStorage.setItem('profilePhotoBase64', imageUrl);
        } catch (error) {
          console.error('Resim alınamadı:', error);
        }
      }
    };
    getImage();

    // Profil doluluk
    const fieldsWeCareAbout = [
      'firstName',
      'lastName',
      'email',
      'phoneNumber',
      'iban',
      'address',
      'identityNumber',
    ];
    let filledCount = 0;
    fieldsWeCareAbout.forEach((field) => {
      if (response.data[field]) {
        filledCount++;
      }
    });
    const totalRequiredFields = fieldsWeCareAbout.length; // 7
    const profileCompletion = (filledCount / totalRequiredFields) * 100;
    localStorage.setItem('profileCompletion', profileCompletion.toString());

    localStorage.setItem('isPartner', response.data.isPartner);
    const roles = response.data.roles;
    localStorage.setItem('roles', JSON.stringify(roles));

    if (roles && roles.length > 0) {
      const permissions = roles.flatMap((role: any) =>
        role.permissions.map((permission: any) => permission.name)
      );
      localStorage.setItem('permissions', JSON.stringify(permissions));
    }

    const userRole = roles?.[0]?.name;
    if (userRole === 'STUDENT') {
      navigate('/');
    } else {
      navigate('/');
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Content>
      {isCookieModal && (
        <CookieConsentModal
          isVisible={isCookieModal}
          onClose={() => setIsCookieModal(false)}
        />
      )}
      <Row justify="center">
        <img
          src={Logo}
          alt="Kontrol X"
          className="logo"
          height={40}
          style={{ margin: '20px 0 10px 0' }}
        />
      </Row>
      <Row justify="center">
        <Title level={2} className="form-header-title">
          Giriş Yap
        </Title>
      </Row>
      <Form form={form} onFinish={onFinish}>
        <Form.Item
          name="email"
          label="E-posta"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          rules={[{ required: true, message: 'Lütfen e-posta adresininizi giriniz !' }]}
        >
          <Input className="d-input" placeholder="E-posta" type="email" />
        </Form.Item>

        <Form.Item
          name="password"
          label="Şifre"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          rules={[{ required: true, message: 'Lütfen şifrenizi girin!' }]}
        >
          <Input.Password
            placeholder="Şifre"
            className="d-input"
            iconRender={(visible) =>
              visible ? (
                <EyeTwoTone onClick={togglePasswordVisibility} />
              ) : (
                <EyeInvisibleOutlined onClick={togglePasswordVisibility} />
              )
            }
          />
        </Form.Item>

        {loginAttempt >= 3 && (
          <Form.Item>
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={RECAPTCHA_SITE_KEY || ''}
              onChange={onRecaptchaChange}
              size="normal"
            />
          </Form.Item>
        )}

        <Form.Item>
          <Button
            htmlType="submit"
            className="d-button mt-16px"
            type="primary"
            loading={isLoading}
          >
            Giriş
          </Button>
        </Form.Item>
      </Form>
      <Row justify={'end'}>
        <Link to="/forgot-password">Şifremi Unuttum</Link>
      </Row>
      <Divider className="divider">VEYA</Divider>
      <Space size={24} direction="vertical" className="social-login">
        <Button
          className="d-button"
          type="default"
          onClick={() => onProvider({ provider: 'GOOGLE' })}
        >
          <img
            src="https://www.google.com/favicon.ico"
            className="sign-images"
            alt="Google"
          />{' '}
          Google ile devam et
        </Button>
        <Button
          className="d-button"
          type="default"
          onClick={() => onProvider({ provider: 'LINKEDIN' })}
        >
          <img
            src="https://static.licdn.com/aero-v1/sc/h/8s162nmbcnfkg7a0k8nq9wwqo"
            className="sign-images"
            alt="Meta"
          />{' '}
          Linkedin ile devam et
        </Button>
      </Space>
      <p className="fw-400 text-center mt-4">
        Hesabınız Yok mu? <Link to={'/register'}>Kaydol</Link>
      </p>
    </Content>
  );
};

export default Login;