import React, { useRef, useState, useEffect } from "react";
import { Table, Button, Modal, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import UserService from "../../services/UserService";
import { openNotification } from "../../utils";
import AgreementService from "../../services/AgreementService";

interface Contract {
  id: string;
  key: string;
  name: string;
  publicationDate: string;
  lastActionDate: string;
  content: string;
}

/**
 * Profil verisine identityNumber eklendi.
 */
interface ProfileData {
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
  address?: string;
  identityNumber?: string; // Eklenen alan
  roles?: Array<{ name: string }>;
}

const Contracts: React.FC<{
  profileCompletion: number;
  partner: boolean;
  profileData: ProfileData;
}> = ({ profileCompletion, partner, profileData }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedContract, setSelectedContract] = useState<Contract | null>(null);
  const [agreementData, setAgreementData] = useState<Contract[]>([]);

  // Modal içerikteki sözleşme HTML'ini göstermek için kullanılacak <div> ref'i
  const contractRef = useRef<HTMLDivElement>(null);

  /**
   * Tüm temel bilgiler (adres, telefon, e-posta, TC Kimlik No) var mı?
   * Kullanıcı bu dört bilgiyi doldurmadıysa, sözleşme modalı açılmayacak
   * ve "Sözleşmeyi Onayla" butonu devre dışı kalacak.
   */
  const hasEssentialPartnerData = !!(
    profileData?.email &&
    profileData?.phoneNumber &&
    profileData?.address &&
    profileData?.identityNumber
  );

  // AgreementService'den sözleşmeleri çekiyoruz, id=5 olanı ayıklıyoruz
  const fetchAgreement = async () => {
    try {
      const res = await AgreementService.getAllAgreements();
      // id=5 olan sözleşmeyi bul
      const found = res.data.find((a: any) => a.id === 5);
      if (found) {
        // Gelen sözleşme verisini Contract arayüzüne uyarlıyoruz
        const newContract: Contract = {
          id: found.id.toString(),
          key: found.id.toString(),
          name: found.name || "Sözleşme Adı",
          publicationDate: found.publicationDate || "",
          lastActionDate: found.lastActionDate || "",
          content: found.contractHtml || "<p>Sözleşme içeriği bulunamadı.</p>",
        };
        setAgreementData([newContract]);
      }
    } catch (err) {
      console.error(err);
      openNotification("error", "Hata", "Sözleşme yüklenirken bir hata oluştu.");
    }
  };

  useEffect(() => {
    fetchAgreement();
  }, []);

  // Modal açma
  const showModal = (id: string) => {
    if (!hasEssentialPartnerData) {
      openNotification("warning", "Eksik Bilgi", "Önce tüm bilgilerinizi doldurun.");
      return;
    }
    const contract = agreementData.find((c) => c.id === id);
    if (contract) {
      // Varsa placeholder'ları doldur, yoksa olduğu gibi bırak
      let replacedContent = contract.content;

      const placeholders: { [key: string]: string } = {
        '[İş Ortağı İsim]': `${profileData.firstName || "Ad"} ${profileData.lastName || "Soyad"}`,
        "[İş Ortağı Adresi]": profileData.address || "",
        "[İş Ortağı Telefonu]": profileData.phoneNumber || "",
        "[İş Ortağı E-posta Adresi]": profileData.email || "",
        "[İş Ortağı TC Kimlik No]": profileData.identityNumber || "",
      };

      Object.entries(placeholders).forEach(([searchValue, replaceValue]) => {
        if (replacedContent.includes(searchValue)) {
          replacedContent = replacedContent.replaceAll(searchValue, replaceValue);
        }
      });

      const newContract = { ...contract, content: replacedContent };
      setSelectedContract(newContract);
      setIsModalVisible(true);
    }
  };

  // Modal kapama
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  // "Sözleşmeyi Onayla" butonu
  const handleUpdateIsPartner = (value: boolean) => {
    if (!hasEssentialPartnerData) {
      openNotification("warning", "Eksik Bilgi", "Önce tüm bilgilerinizi doldurun.");
      return;
    }
    UserService.updateMe({ isPartner: value })
      .then(() => {
        openNotification(
          "success",
          "Başarılı",
          "İş ortağı işleminiz güncellendi. Artık teklif oluşturabilirsiniz."
        );
        localStorage.setItem("isPartner", "true");
        window.location.reload();
      })
      .catch((e: Error) => {
        console.error(e);
        openNotification(
          "error",
          "Hata",
          "İş ortağı işleminiz güncellenirken bir hata oluştu."
        );
      });
  };

  // "Sözleşmeyi İndir" butonu
  const handleDownload = (id: string) => {
    if (!hasEssentialPartnerData) {
      openNotification("warning", "Eksik Bilgi", "Önce tüm bilgilerinizi doldurun.");
      return;
    }
    const contract = agreementData.find((c) => c.id === id);
    if (!contract) return;

    let replacedContent = contract.content;

    const placeholders: { [key: string]: string } = {
      '[İş Ortağı İsim]': `${profileData.firstName || "Ad"} ${
        profileData.lastName || "Soyad"
      }`,
      "[İş Ortağı Adresi]": profileData.address || "",
      "[İş Ortağı Telefonu]": profileData.phoneNumber || "",
      "[İş Ortağı E-posta Adresi]": profileData.email || "",
      "[İş Ortağı TC Kimlik No]": profileData.identityNumber || "",
    };

    Object.entries(placeholders).forEach(([searchValue, replaceValue]) => {
      if (replacedContent.includes(searchValue)) {
        replacedContent = replacedContent.replaceAll(searchValue, replaceValue);
      }
    });

    // 1) Geçici <div> oluşturup sözleşme HTML'ini içine atacağız
    const ephemeralDiv = document.createElement("div");
    ephemeralDiv.style.width = "210mm";
    ephemeralDiv.style.padding = "20px";
    ephemeralDiv.style.background = "#fff";
    ephemeralDiv.innerHTML = replacedContent;

    document.body.appendChild(ephemeralDiv);

    // 2) html2canvas ile screenshot al
    setTimeout(() => {
      html2canvas(ephemeralDiv, { scale: 1 }).then((canvas) => {
        // 3) JS PDF'e ekle
        const dataURL = canvas.toDataURL("image/png");
        const doc = new jsPDF("p", "pt", "a4");

        // Opsiyonel font ekleme
        (jsPDF as any).API.events.push([
          "addFonts",
          function () {
            doc.addFileToVFS("DejaVuSans.ttf", "AAEAAA...");
            doc.addFont("DejaVuSans.ttf", "DejaVuSans", "normal");
          },
        ]);
        doc.setFont("DejaVuSans", "normal");

        const img = new Image();
        img.src = dataURL;
        img.onload = () => {
          const pageWidth = doc.internal.pageSize.getWidth();
          const margin = 20;
          const maxWidth = pageWidth - margin * 2;
          const aspectRatio = img.height / img.width;
          const calcHeight = maxWidth * aspectRatio;

          doc.addImage(img, "PNG", margin, margin, maxWidth, calcHeight);
          doc.save(`${contract.name}.pdf`);
          // Temizle
          document.body.removeChild(ephemeralDiv);
        };
      });
    }, 300);
  };

  // Tablonun kolonları
  const columns: ColumnsType<Contract> = [
    {
      title: "Sözleşme Adı",
      dataIndex: "name",
      key: "name",
      render: (text, record) => {
        return (
          <Tooltip
            title={
              !hasEssentialPartnerData
                ? "Sözleşmeyi onaylamanız için Adres, Telefon ve TC Kimlik bilgilerinizi eksiksiz doldurmanız gerekmektedir."
                : ""
            }
          >
            <span
              onClick={() => {
                if (hasEssentialPartnerData) {
                  showModal(record.id);
                }
              }}
              style={{
                color: hasEssentialPartnerData ? "#1890ff" : "gray",
                cursor: hasEssentialPartnerData ? "pointer" : "not-allowed",
                textDecoration: hasEssentialPartnerData ? "underline" : "none",
              }}
            >
              {text}
            </span>
          </Tooltip>
        );
      },
    },
    {
      title: "İşlem",
      key: "action",
      render: (_, record) => {
        const canApprove =
          profileCompletion === 100 && !partner && hasEssentialPartnerData;

        return (
          <div>
            <Tooltip
              title={
                !hasEssentialPartnerData
                  ? "Sözleşmeyi onaylamanız için Adres, Telefon ve TC Kimlik bilgilerinizi eksiksiz doldurmanız gerekmektedir."
                  : profileCompletion !== 100
                  ? "Tüm profil bilgilerini doldurmalısınız!"
                  : partner
                  ? "Zaten İş Ortağı oldunuz!"
                  : ""
              }
            >
              <Button
                type="primary"
                style={{ marginRight: 10 }}
                className="d-button dark-button width-auto"
                disabled={!canApprove || partner}
                onClick={() => handleUpdateIsPartner(true)}
              >
                Sözleşmeyi Onayla
              </Button>
            </Tooltip>
            {/* Sözleşmeyi indir butonu her zaman aktif => 
                ama veriler eksikse fonksiyon içinde uyarı */}
            <Button
              type="primary"
              onClick={() => handleDownload(record.id)}
              className="d-button dark-button width-auto"
            >
              Sözleşmeyi İndir
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <Table
        scroll={{ x: 768 }}
        columns={columns}
        dataSource={agreementData}
        pagination={false}
      />

      <Modal
        centered
        bodyStyle={{ maxHeight: "80vh", overflowY: "auto" }}
        title={selectedContract?.name}
        width={"auto"}
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <div
          ref={contractRef}
          style={{
            border: 1,
            borderColor: "black",
            width: "210mm",
            minHeight: "auto",
            background: "#fff",
            margin: "0 auto",
            padding: 20,
            boxSizing: "border-box",
            transformOrigin: "top left",
            position: "relative",
          }}
        >
          <style>
            {`
              @media (max-width: 600px) {
                div[style*="210mm"] {
                  transform: scale(0.6);
                }
              }
              p {
                margin-bottom: 10px;
              }
            `}
          </style>

          {selectedContract && (
            <div dangerouslySetInnerHTML={{ __html: selectedContract.content }} />
          )}
        </div>
      </Modal>
    </div>
  );
};

export default Contracts;