import React, { useState, useEffect } from "react";
import {
    Button,
    Table,
    Modal,
    Form,
    Input,
    Select,
    message,
    Typography,
    Card,
    Row,
    Popconfirm
} from "antd";
import TrashIcon from '../../assets/icons/trash.svg';
import PenIcon from '../../assets/icons/pen.svg';
import { formatDate, openNotification } from "../../utils";
import { SearchOutlined } from '@ant-design/icons';
import AnnouncementService from "../../services/AnnouncementService";
import { render } from "@testing-library/react";

const { Option } = Select;
const { Title } = Typography;

/**
 * Enum Değerlerini -> Türkçe metinlere dönüştürmek için bir yardımcı obje.
 */
const ANNOUNCEMENT_TYPE_LABELS: Record<string, string> = {
    INFORMATION: "Bilgilendirme",
    UPDATE: "Güncelleme",
    EVENT: "Etkinlik",
};

const AnnouncementList = () => {
    const [announcements, setAnnouncements] = useState<any[]>([]);
    const [filteredAnnouncements, setFilteredAnnouncements] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [editingAnnouncement, setEditingAnnouncement] = useState<any | null>(null);
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [form] = Form.useForm();

    useEffect(() => {
        loadAnnouncements();
    }, []);

    useEffect(() => {
        const filtered = announcements.filter((announcement) => {
            const matchesSearch =
                announcement.header?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                announcement.description?.toLowerCase().includes(searchTerm.toLowerCase());
            return matchesSearch;
        });
        setFilteredAnnouncements(filtered);
    }, [searchTerm, announcements]);

    const loadAnnouncements = async () => {
        setLoading(true);
        try {
            const response = await AnnouncementService.getAllAnnouncements();
            setAnnouncements(response.data.content);
            setFilteredAnnouncements(response.data.content);
        } catch (error) {
            message.error("Duyurular yüklenirken bir hata oluştu.");
        } finally {
            setLoading(false);
        }
    };

    const handleCreate = () => {
        setEditingAnnouncement(null);
        form.resetFields();
        setIsModalVisible(true);
    };

    const handleEdit = (announcement: any) => {
        setEditingAnnouncement(announcement);
        // Form değerlerini set ederken enum alanını da dolduruyoruz
        form.setFieldsValue({
            ...announcement,
            // Herhangi bir ek manipülasyon gerekiyorsa burada yapabilirsiniz
        });
        setIsModalVisible(true);
    };

    const handleDelete = async (id: number) => {
        try {
            await AnnouncementService.deleteAnnouncement(id);
            openNotification('success', 'Başarılı', "Duyuru silindi.");
            loadAnnouncements();
        } catch (error: any) {
            if (error.response && error.response.status === 409) {
                openNotification(
                    'error',
                    'Hata',
                    "Silme işlemi gerçekleştirilemez. Bu duyuru aktif olarak kullanılmaktadır."
                );
            } else {
                openNotification('error', 'Hata', "Duyuru silinemedi.");
            }
        }
    };

    const handleSubmit = async (values: any) => {
        try {
            if (editingAnnouncement) {
                // Güncelleme
                await AnnouncementService.updateAnnouncement(editingAnnouncement.id, values);
                openNotification('success', 'Başarılı', "Duyuru güncellendi.");
            } else {
                // Yeni oluşturma
                await AnnouncementService.createAnnouncement(values);
                openNotification('success', 'Başarılı', "Duyuru oluşturuldu.");
            }
            loadAnnouncements();
            setIsModalVisible(false);
        } catch (error) {
            openNotification('error', 'Başarısız', "İşlemde hata meydana geldi.");
        }
    };

    // Tabloda yeni bir sütun ekleyerek enum değerini Türkçe gösteriyoruz:
    const columns = [
        {
            title: "Başlık",
            dataIndex: "header",
            key: "header",
        },
        {
            title: "Açıklama",
            dataIndex: "description",
            key: "description",
        },
        {
            title: "Duyuru Tipi",
            dataIndex: "type",
            key: "type",
            render: (typeValue: string) => ANNOUNCEMENT_TYPE_LABELS[typeValue] || typeValue,
        },
        {
            title: "Oluşturma Tarihi",
            dataIndex: "createdAt",
            key: "createdAt",
            render: (text: any) => <span>{formatDate(text)}</span>,
        },
        {
            title: "Güncellenme Tarihi",
            dataIndex: "updatedAt",
            key: "updatedAt",
            render: (text: any) => <span>{formatDate(text)}</span>,
        },
        {
            title: "İşlemler",
            key: "actions",
            width: 140,
            render: (_: any, record: any) => (
                <div>
                    <Button
                        shape="circle"
                        icon={<img src={PenIcon} alt="edit" className="icon" />}
                        onClick={() => handleEdit(record)}
                        className="icon-button mr-10px"
                    />
                    <Popconfirm
                        title="Bu duyuruyu silmek istediğinize emin misiniz?"
                        onConfirm={() => handleDelete(record.id)}
                        okText="Evet"
                        cancelText="Hayır"
                    >
                        <Button
                            shape="circle"
                            icon={<img src={TrashIcon} alt="delete" className="icon" />}
                            className="icon-button"
                        />
                    </Popconfirm>
                </div>
            ),
        },
    ];

    return (
        <Card className="br-30px">
            <Row justify="space-between" align="middle" style={{ marginBottom: 16 }}>
                <Title style={{ margin: 0 }} level={4}>Duyuru Listesi</Title>
                <Row>
                    <Input
                        placeholder="Ara"
                        prefix={<SearchOutlined style={{ color: '#b8b9bc' }} />}
                        className="d-input d-input-form mr-16px d-input-filter"
                        style={{ width: 200, marginRight: 16 }}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <Button
                        type="primary"
                        className="d-button dark-button width-auto"
                        onClick={handleCreate}
                    >
                        Yeni Duyuru Ekle
                    </Button>
                </Row>
            </Row>

            <Table
                dataSource={filteredAnnouncements}
                columns={columns}
                rowKey="id"
                loading={loading}
                pagination={{
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '20', '30'],
                }}
            />

            <Modal
                title={editingAnnouncement ? "Duyuru Düzenle" : "Yeni Duyuru Ekle"}
                visible={isModalVisible}
                footer={null}
                onCancel={() => setIsModalVisible(false)}
            >
                <Form
                    form={form}
                    onFinish={handleSubmit}
                    layout="vertical"
                // Tüm alanların required olduğunu hatırlatalım.
                >
                    <Form.Item
                        label="Başlık"
                        name="header"
                        rules={[
                            { required: true, message: 'Lütfen duyuru başlığı giriniz' },
                            { max: 255, message: 'Başlık en fazla 255 karakter olabilir' },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Açıklama"
                        name="description"
                        rules={[
                            { required: true, message: 'Lütfen duyuru açıklaması giriniz' },
                            { max: 1000, message: 'Açıklama en fazla 1000 karakter olabilir' },
                        ]}
                    >
                        <Input.TextArea />
                    </Form.Item>

                    <Form.Item
                        label="Duyuru Tipi"
                        name="type"
                        rules={[
                            { required: true, message: 'Lütfen duyuru tipini seçiniz' },
                        ]}
                    >
                        <Select placeholder="Duyuru tipini seçiniz">
                            <Option value="INFORMATION">Bilgilendirme</Option>
                            <Option value="UPDATE">Güncelleme</Option>
                            <Option value="EVENT">Etkinlik</Option>
                        </Select>
                    </Form.Item>

                    <Row justify="center">
                        <Button
                            type="primary"
                            htmlType="submit"
                            className="d-button dark-button width-auto"
                        >
                            {editingAnnouncement ? "Kaydet" : "Kaydet"}
                        </Button>
                    </Row>
                </Form>
            </Modal>
        </Card>
    );
};

export default AnnouncementList;