import React, { useState, useEffect } from "react";
import { Button, Table, Modal, Form, Input, Select, message, Typography, Card, Row, Popconfirm } from "antd";
import ToolService from "../../services/ToolService";
import TrashIcon from '../../assets/icons/trash.svg';
import PenIcon from '../../assets/icons/pen.svg';
import { openNotification } from "../../utils";
import { SearchOutlined } from '@ant-design/icons';

const { Option } = Select;

const ToolList = () => {
    const [tools, setTools] = useState<any[]>([]);
    const [filteredTools, setFilteredTools] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [editingTool, setEditingTool] = useState<any | null>(null);
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [form] = Form.useForm();
    const { Title } = Typography;

    useEffect(() => {
        loadTools();
    }, []);

    useEffect(() => {
        const filtered = tools.filter((tool) => {
            const matchesSearch = tool.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                tool.description?.toLowerCase().includes(searchTerm.toLowerCase());


            return matchesSearch;
        });

        setFilteredTools(filtered);
    }, [searchTerm, tools]);

    const loadTools = async () => {
        setLoading(true);
        try {
            const response = await ToolService.getAllTools();
            setTools(response.data);
            setFilteredTools(response.data);
        } catch (error) {
            message.error("Error loading tools.");
        } finally {
            setLoading(false);
        }
    };


    const handleCreate = () => {
        setEditingTool(null);
        form.resetFields();
        setIsModalVisible(true);
    };

    const handleEdit = (tool: any) => {
        setEditingTool(tool);
        form.setFieldsValue(tool);
        setIsModalVisible(true);
    };

    const handleDelete = async (id: number) => {
        try {
            await ToolService.deleteTool(id);
            openNotification('success', 'Başarılı', "Bileşen silindi.");
            loadTools();
        } catch (error: any) {
            if (error.response && error.response.status === 409) {
                openNotification('error', 'Hata', "Silme işlemi gerçekleştirilemez. Bu bileşen aktif olarak kullanılmaktadır.");
            } else {
                openNotification('error', 'Hata', "Bileşen silinemedi.");
            }
        }
    };

    const handleSubmit = async (values: any) => {
        try {
            if (editingTool) {
                await ToolService.updateTool(editingTool.id, values);
                openNotification('success', 'Başarılı', "Bileşen güncellendi.");
            } else {
                await ToolService.createTool(values);
                openNotification('success', 'Başarılı', "Bileşen oluşturuldu.");
            }
            loadTools();
            setIsModalVisible(false);
        } catch (error) {
            openNotification('error', 'Başarısız', "İşlemde hata meydana geldi.");
        }
    };

    const columns = [
        {
            title: "Adı",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Açıklama",
            dataIndex: "description",
            key: "description",
        },
        {
            title: "İşlemler",
            key: "actions",
            width: 140,
            render: (_: any, record: any) => (
                <div >
                    <Button
                        shape="circle"
                        icon={<img src={PenIcon} alt="edit" className="icon" />}
                        onClick={() => handleEdit(record)}
                        className='icon-button mr-10px'
                    />
                    <Popconfirm
                        title="Bu bileşeni silmek istediğinize emin misiniz?"
                        onConfirm={() => handleDelete(record.id)}
                        okText="Evet"
                        cancelText="Hayır"
                    >
                        <Button
                            shape="circle"
                            icon={<img src={TrashIcon} alt="delete" className="icon" />}
                            className='icon-button'
                        />
                    </Popconfirm>
                </div>
            ),
        },
    ];

    return (
        <Card className="br-30px">
            <Row justify="space-between" align={'middle'} style={{ marginBottom: 16 }}>
                <Title style={{ margin: 0 }} level={4}>Bileşen Listesi</Title>
                <Row>
                    <Input
                        placeholder="Ara"
                        prefix={<SearchOutlined style={{ color: '#b8b9bc' }} />}
                        className="d-input d-input-form mr-16px d-input-filter"
                        style={{ width: 200, marginRight: 16 }}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <Button type="primary" className="d-button dark-button width-auto" onClick={handleCreate}>
                        Yeni Bileşen Ekle
                    </Button>
                </Row>
            </Row>
            <Table
                dataSource={filteredTools}
                columns={columns}
                rowKey="id"
                loading={loading}
                pagination={{
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '20', '30'],
                }}
            />
            <Modal
                title={editingTool ? "Bileşen Düzenle" : "Yeni Bileşen Ekle"}
                visible={isModalVisible}
                footer={null}
                onCancel={() => setIsModalVisible(false)}
            >
                <Form form={form} onFinish={handleSubmit} layout="vertical">
                    <Form.Item
                        label="Adı"
                        name="name"
                        rules={[{ required: true, message: 'Lütfen bileşen adı giriniz' },
                        { max: 255, message: 'Adı en fazla 255 karakter olabilir' },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Açıklama"
                        name="description"
                        rules={[
                            { max: 1000, message: 'Açıklama en fazla 1000 karakter olabilir' },
                        ]}
                    >
                        <Input.TextArea />
                    </Form.Item>

                    <Row justify={'center'}>
                        <Button type="primary" htmlType="submit" className="d-button dark-button width-auto">
                            {editingTool ? "Güncelle" : "Kaydet"}
                        </Button>
                    </Row>
                </Form>
            </Modal>
        </Card>
    );
};

export default ToolList;