import { Button, Col, Form, Input, Row, Typography, Layout } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { openNotification } from '../../utils';
import './auth.scss';
import lockIcon from './../../assets/auth/lock-icon.png';

const { Title, Text } = Typography;
const { Content } = Layout;

const EmailVerifySend = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [email, setEmail] = useState<string>('');
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);

  useEffect(() => {
    // URL'den e-posta parametresini al
    const queryParams = new URLSearchParams(location.search);
    const emailParam = queryParams.get('email');
    if (emailParam) {
      setEmail(emailParam);
    }
  }, [location.search]);

  const handleSendVerificationEmail = () => {
    if (isButtonDisabled) return;

    const userData = { email };

    const apiUrl = process.env.REACT_APP_API_BASE_URL + '/auth/verification-send';

    axios.post(apiUrl, userData)
      .then((response) => {
        openNotification('success', 'Başarılı', 'Onay e-postası gönderildi. Lütfen E-Postanızı kontrol edin.');
        setIsButtonDisabled(true);
        setTimeout(() => {
          setIsButtonDisabled(false);
        }, 60000); // 1 dakika sonra butonu aktif et
      })
      .catch((e) => {
        console.log(e);
        openNotification('error', 'Başarısız', 'Bir hata oluştu. Kontrol ederek tekrar deneyin.');
      });
  };

  return (
    <Content>
      <Row justify={'center'} style={{ height: '100vh' }}>
        <Col lg={14} md={20} xs={22}>
          <div className='form-header'>
            <Title level={1}>Kontrol X</Title>
          </div>
          <Row align={'middle'} justify={'center'} className='text-center mb-4 '>
            <Title level={2}>Aktivasyon E-postası gönder</Title>
            <p className='mt-4 text-center'>
              E-posta adresinize bir onay e-postası göndereceğiz.
            </p>
          </Row>

          <Row justify={'center'}>
            <Button
              onClick={handleSendVerificationEmail}
              className='d-button mt-16px'
              type='primary'
              disabled={isButtonDisabled}
            >
              Onay E-Postası Gönder
            </Button>
          </Row>
        </Col>
      </Row>
    </Content>
  );
};

export default EmailVerifySend;
