import { http } from "../http-common";

/**
 * Paginated rapor sorgusu
 * Parametreler obje olarak verilir; örneğin:
 * {
 *   accountId: number | null,
 *   page: number,
 *   size: number,
 *   sort: string // örn. "createdAt,desc"
 * }
 */
const getPartnerReportPaginated = (params: any) => {
  return http.get<any>("/partner-report", { params });
};

/**
 * Sayfalama olmadan rapor sorgusu
 * Parametre olarak filtre bilgileri verilebilir; örneğin:
 * { accountId: number | null }
 */
const getPartnerReportAll = (params?: any) => {
  return http.get<any>("/partner-report/all", { params });
};

const PartnerReportService = {
  getPartnerReportPaginated,
  getPartnerReportAll,
};

export default PartnerReportService;