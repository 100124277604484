import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Timeline } from "antd";
import dayjs from "dayjs";
import AccountActivityService from "../../services/AccountActivityService";

interface ActivityLog {
  entityName: string;   // Örnek: "Proposal", "Ticket", "Company", "Addresses", "AuthorizedPerson", "Note", "Account"...
  action: string;       // "CREATE" | "UPDATE" | "DELETE" vb.
  createdAt: string;    // Tarih string
  newValue?: string;
  oldValue?: string;
}

const ActivityTimeline: React.FC = () => {
  const [logs, setLogs] = useState<ActivityLog[]>([]);
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    if (id) {
      AccountActivityService.getLogs("", id)
        .then((res) => setLogs(res.data))
        .catch((err) => console.error("Logları alırken hata oluştu:", err));
    }
  }, [id]);

  // -------------------------------------------------
  // 1) Teklif (Proposal) Güncellemelerini Metinsel Açıklamaya Çeviren Fonksiyon
  // -------------------------------------------------
  const getProposalUpdateMessage = (newValObj: any, oldValObj: any): string => {
    const changes: string[] = [];

    const oldProposalNumber = oldValObj?.proposalNumber;
    const newProposalNumber = newValObj?.proposalNumber;
    const oldTotalPrice = oldValObj?.totalPrice;
    const newTotalPrice = newValObj?.totalPrice;
    const oldMaxDiscount = oldValObj?.maxDiscountPrice;
    const newMaxDiscount = newValObj?.maxDiscountPrice;

    // 1) Teklif numarası + revize kontrol
    if (oldProposalNumber !== newProposalNumber) {
      const pn = newProposalNumber ?? oldProposalNumber;
      if (oldTotalPrice != newTotalPrice) {
        if (pn) {
          changes.push(`${pn} numaralı teklifte revize yapıldı.`);
        }
      }
    } else {
      // 2) İndirim kontrolü
      if (oldValObj.originalPrice === newValObj.originalPrice) {
        if (oldMaxDiscount != newMaxDiscount) {
          const pn = newProposalNumber || oldProposalNumber || "Bu";
          changes.push(`${pn} numaralı teklif için kullanıcı indirim yaptı.`);
        }
      }
    }

    // 3) Sözleşme yükleme
    const oldDocUpload = oldValObj?.documentUploadedAt;
    const newDocUpload = newValObj?.documentUploadedAt;
    if (oldDocUpload !== newDocUpload) {
      const pn = newProposalNumber || oldProposalNumber || "Bu";
      if (!oldDocUpload && newDocUpload) {
        changes.push(`${pn} numaralı teklif için Sözleşme yüklendi.`);
      } else {
        changes.push(`${pn} numaralı teklif için Sözleşme revize edilerek tekrar yüklendi.`);
      }
    }

    // 4) Planlama
    const oldRequestedPlan = oldValObj?.requestedPlanningStartDate;
    const newRequestedPlan = newValObj?.requestedPlanningStartDate;
    if (oldRequestedPlan !== newRequestedPlan) {
      const pn = newProposalNumber || oldProposalNumber || "Bu";
      changes.push(`${pn} numaralı teklif için kullanıcı planlama tarihi isteğinde bulundu.`);
    }

    // 5) Ödeme
    const oldIsPaid = oldValObj?.isPaid;
    const newIsPaid = newValObj?.isPaid;
    if (oldIsPaid !== newIsPaid) {
      const pn = newProposalNumber || oldProposalNumber || "Bu";
      changes.push(`${pn} numaralı teklif için ödeme yapıldı.`);
    }

    // 6) Durum güncellemesi
    const oldStatus = oldValObj?.status?.id;
    const newStatus = newValObj?.status?.id;
    if (oldStatus !== newStatus) {
      if (newStatus === 3) {
        const pn = newProposalNumber || oldProposalNumber || "Bu";
        changes.push(`${pn} numaralı teklif şirkete iletildi.`);
      }
      if (newStatus === 2) {
        const pn = newProposalNumber || oldProposalNumber || "Bu";
        changes.push(`${pn} numaralı teklif iptal edildi.`);
      }
    }

    if (changes.length === 0) {
      return "";
    }
    return changes.join("\n");
  };

  // -------------------------------------------------
  // 2) Şirket (Company) Güncellemelerini Metinsel Açıklamaya Çeviren Fonksiyon
  // -------------------------------------------------
  const getCompanyUpdateMessage = (newValObj: any, oldValObj: any): string => {
    const changes: string[] = [];

    const oldName = oldValObj?.name;
    const newName = newValObj?.name;
    if (oldName !== newName) {
      const fromVal = oldName || "(Boş)";
      const toVal = newName || "(Boş)";
      changes.push(`Firma adı "${fromVal}" → "${toVal}" değerine güncellendi.`);
    }

    const oldTaxOffice = oldValObj?.taxOffice;
    const newTaxOffice = newValObj?.taxOffice;
    if (oldTaxOffice !== newTaxOffice) {
      const fromVal = oldTaxOffice || "(Boş)";
      const toVal = newTaxOffice || "(Boş)";
      changes.push(`Vergi dairesi "${fromVal}" → "${toVal}" şeklinde güncellendi.`);
    }

    const oldTaxNumber = oldValObj?.taxNumber;
    const newTaxNumber = newValObj?.taxNumber;
    if (oldTaxNumber !== newTaxNumber) {
      const fromVal = oldTaxNumber || "(Boş)";
      const toVal = newTaxNumber || "(Boş)";
      changes.push(`Vergi numarası "${fromVal}" → "${toVal}" şeklinde güncellendi.`);
    }

    const oldEmail = oldValObj?.email;
    const newEmail = newValObj?.email;
    if (oldEmail !== newEmail) {
      const fromVal = oldEmail || "(Boş)";
      const toVal = newEmail || "(Boş)";
      changes.push(`Email "${fromVal}" → "${toVal}" şeklinde güncellendi.`);
    }

    const oldPhone = oldValObj?.phoneNumber;
    const newPhone = newValObj?.phoneNumber;
    if (oldPhone !== newPhone) {
      const fromVal = oldPhone || "(Boş)";
      const toVal = newPhone || "(Boş)";
      changes.push(`Telefon numarası "${fromVal}" → "${toVal}" şeklinde güncellendi.`);
    }

    const oldKep = oldValObj?.kepAddress;
    const newKep = newValObj?.kepAddress;
    if (oldKep !== newKep) {
      const fromVal = oldKep || "(Boş)";
      const toVal = newKep || "(Boş)";
      changes.push(`KEP adresi "${fromVal}" → "${toVal}" şeklinde güncellendi.`);
    }

    // Daha fazla alan kıyaslaması eklenebilir

    if (changes.length === 0) {
      return "";
    }
    return changes.join("\n");
  };

  // -------------------------------------------------
  // 3) Adres - AuthorizedPerson - Note Logları
  // -------------------------------------------------
  const getAddressOrAuthorizedOrNoteMessage = (
    entityName: string,
    action: string,
    newValObj?: any,
    oldValObj?: any
  ): string => {
    // Addresses
    if (entityName === "Addresses") {
      if (action === "CREATE") return "Adres eklendi.";
      if (action === "UPDATE") return "Adres güncellendi.";
      if (action === "DELETE") return "Adres silindi.";
      return "";
    }

    // AuthorizedPerson
    if (entityName === "AuthorizedPerson") {
      const firstName = newValObj?.firstName || oldValObj?.firstName || "(isim yok)";
      const lastName = newValObj?.lastName || oldValObj?.lastName || "";
      const fullName = `${firstName} ${lastName}`.trim();

      if (action === "CREATE") {
        return `${fullName} adında yetkili kişi eklendi.`;
      }
      if (action === "UPDATE") {
        return `${fullName} adında yetkili kişi güncellendi.`;
      }
      if (action === "DELETE") {
        return `${fullName} adında yetkili kişi silindi.`;
      }
      return "";
    }

    // Note
    if (entityName === "Note") {
      if (action === "CREATE") return "Bir not eklendi.";
      if (action === "UPDATE") return "Bir not güncellendi.";
      if (action === "DELETE") return "Bir not silindi.";
      return "";
    }

    return "";
  };

  // -------------------------------------------------
  // 4) Account Güncellemelerini Metinsel Açıklamaya Çeviren Fonksiyon
  // -------------------------------------------------
  /**
   * Account için: firstName, lastName, email, phone, profilePhoto, password, emailVerified
   * CREATE => "Yeni bir hesap oluşturuldu." (veya "Yeni bir Account oluşturuldu.")
   * UPDATE => Değişen alanlara göre tek tek "X'ten Y'ye güncellendi" vb.
   * DELETE => "Account silindi."
   */
  const getAccountUpdateMessage = (newValObj: any, oldValObj: any): string => {
    const changes: string[] = [];

    // firstName
    const oldF = oldValObj?.firstName;
    const newF = newValObj?.firstName;
    if (oldF !== newF) {
      changes.push(`Ad "${oldF ?? "(Boş)"}" → "${newF ?? "(Boş)"}" değerine güncellendi.`);
    }

    // lastName
    const oldL = oldValObj?.lastName;
    const newL = newValObj?.lastName;
    if (oldL !== newL) {
      changes.push(`Soyad "${oldL ?? "(Boş)"}" → "${newL ?? "(Boş)"}" değerine güncellendi.`);
    }

    // email
    const oldE = oldValObj?.email;
    const newE = newValObj?.email;
    if (oldE !== newE) {
      changes.push(`Email "${oldE ?? "(Boş)"}" → "${newE ?? "(Boş)"}" değerine güncellendi.`);
    }

    // phone
    const oldPh = oldValObj?.phone;
    const newPh = newValObj?.phone;
    if (oldPh !== newPh) {
      changes.push(`Telefon "${oldPh ?? "(Boş)"}" → "${newPh ?? "(Boş)"}" şeklinde güncellendi.`);
    }

    // profilePhoto
    const oldPhoto = oldValObj?.profilePhoto;
    const newPhoto = newValObj?.profilePhoto;
    if (oldPhoto !== newPhoto) {
      if (!oldPhoto && newPhoto) {
        changes.push("Profil fotoğrafı eklendi.");
      } else if (oldPhoto && !newPhoto) {
        changes.push("Profil fotoğrafı kaldırıldı.");
      } else {
        changes.push("Profil fotoğrafı güncellendi.");
      }
    }

    // password (Güvenlik açısından log tutmak sakıncalı olabilir ama talep edildi)
    const oldPass = oldValObj?.password;
    const newPass = newValObj?.password;
    if (oldPass !== newPass) {
      changes.push("Kullanıcı şifresi güncellendi.");
    }

    // emailVerified
    const oldEV = oldValObj?.emailVerified;
    const newEV = newValObj?.emailVerified;
    if (oldEV !== newEV) {
      if (newEV === true) {
        changes.push("Email adresi doğrulandı (verified).");
      } else if (newEV === false) {
        changes.push("Email adresi tekrar doğrulanmamış duruma getirildi.");
      }
    }

    if (changes.length === 0) {
      return "";
    }
    return changes.join("\n");
  };

  // -------------------------------------------------
  // 5) Her Log Kayıt Tipine Göre Türkçe Mesaj
  // -------------------------------------------------
  const getActivityDescription = (log: ActivityLog): string => {
    const { entityName, action, newValue, oldValue } = log;

    // 1) Addresses / AuthorizedPerson / Note
    if (
      entityName === "Addresses" ||
      entityName === "AuthorizedPerson" ||
      entityName === "Note"
    ) {
      try {
        const newValObj = newValue ? JSON.parse(newValue) : {};
        const oldValObj = oldValue ? JSON.parse(oldValue) : {};
        return getAddressOrAuthorizedOrNoteMessage(entityName, action, newValObj, oldValObj);
      } catch {
        return "";
      }
    }

    // 2) Ticket
    if (entityName === "Ticket") {
      if (action === "CREATE") return "Yeni bir destek talebi oluşturuldu.";
      if (action === "UPDATE") return "Destek talebi güncellendi.";
      if (action === "DELETE") return "Destek talebi silindi.";
    }

    // 3) TicketResponse
    if (entityName === "TicketResponse") {
      if (action === "CREATE") return "Destek talebine bir mesaj yazıldı.";
      if (action === "UPDATE") return "Destek talebindeki mesaj güncellendi.";
      if (action === "DELETE") return "Destek talebindeki mesaj silindi.";
    }

    // 4) Proposal
    if (entityName === "Proposal") {
      if (action === "CREATE") {
        return "Yeni bir teklif oluşturuldu.";
      }
      if (action === "UPDATE") {
        try {
          const newValObj = newValue ? JSON.parse(newValue) : {};
          const oldValObj = oldValue ? JSON.parse(oldValue) : {};
          return getProposalUpdateMessage(newValObj, oldValObj);
        } catch {
          return "";
        }
      }
      if (action === "DELETE") {
        return "Teklif silindi.";
      }
    }

    // 5) Company
    if (entityName === "Company") {
      if (action === "CREATE") {
        return "Yeni bir firma oluşturuldu.";
      }
      if (action === "UPDATE") {
        try {
          const newValObj = newValue ? JSON.parse(newValue) : {};
          const oldValObj = oldValue ? JSON.parse(oldValue) : {};
          return getCompanyUpdateMessage(newValObj, oldValObj);
        } catch {
          return "";
        }
      }
      if (action === "DELETE") {
        return "Firma silindi.";
      }
    }

    // 6) Customer
    if (entityName === "Customer") {
      if (action === "CREATE") {
        return "Yeni bir müşteri oluşturuldu.";
      }
      if (action === "UPDATE") {
        return "Müşteri bilgileri güncellendi.";
      }
      if (action === "DELETE") {
        return "Müşteri silindi.";
      }
    }

    // 7) Account
    if (entityName === "Account") {
      if (action === "CREATE") {
        return "Yeni bir hesap oluşturuldu.";
      }
      if (action === "UPDATE") {
        try {
          const newValObj = newValue ? JSON.parse(newValue) : {};
          const oldValObj = oldValue ? JSON.parse(oldValue) : {};
          return getAccountUpdateMessage(newValObj, oldValObj);
        } catch {
          return "";
        }
      }
      if (action === "DELETE") {
        return "Hesap silindi.";
      }
    }

    // Diğer durumlarda boş döndür, timeline'a yansımasın
    return "";
  };

  // -------------------------------------------------
  // 6) Tekrarlayan Ardışık Mesajları Filtrele
  // -------------------------------------------------
  const filteredLogs: ActivityLog[] = [];

  // Son mesaj + "anahtar" (ProposalNumber, FirmaAdı vb.)
  let lastMessage = "";
  let lastKey = ""; // Teklif numarası veya Firma Adı vb. tutabiliriz

  logs.forEach((log) => {
    const description = getActivityDescription(log);
    if (!description) return; // Boş string ise ekleme

    let currentKey = "";

    // Örnek: Tekrarlı mesaj önleme, sadece Proposal / Company vb.  
    // (Account vb. için de ID ya da email'i key olarak tutmak isterseniz ekleyin.)
    if (log.entityName === "Proposal" && log.action === "UPDATE") {
      try {
        const newValObj = log.newValue ? JSON.parse(log.newValue) : {};
        const oldValObj = log.oldValue ? JSON.parse(log.oldValue) : {};
        currentKey = newValObj?.proposalNumber ?? oldValObj?.proposalNumber ?? "";
      } catch {}
    }
    if (log.entityName === "Company" && log.action === "UPDATE") {
      try {
        const newValObj = log.newValue ? JSON.parse(log.newValue) : {};
        const oldValObj = log.oldValue ? JSON.parse(log.oldValue) : {};
        currentKey = newValObj?.name ?? oldValObj?.name ?? "";
      } catch {}
    }

    const isDuplicate = description === lastMessage && currentKey === lastKey;

    if (!isDuplicate) {
      filteredLogs.push(log);
      lastMessage = description;
      lastKey = currentKey;
    }
  });

  return (
    <Timeline>
      {filteredLogs.map((log, idx) => {
        const description = getActivityDescription(log);
        if (!description) return null;

        const lines = description.split("\n").map((str, i) => (
          <div key={i}>{str}</div>
        ));
        const dateStr = dayjs(log.createdAt).format("DD/MM/YYYY HH:mm");

        return (
          <Timeline.Item key={idx}>
            {lines}
            <span style={{ color: "#999" }}> ({dateStr})</span>
          </Timeline.Item>
        );
      })}
    </Timeline>
  );
};

export default ActivityTimeline;