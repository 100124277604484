import { http } from "../http-common";

interface AnnouncementQueryParams {
  page?: number;
  size?: number;
  search?: string;
}

const getAllAnnouncements = (params?: AnnouncementQueryParams) => {
  return http.get('/announcements', { params });
};

const createAnnouncement = (data: any) => {
  return http.post('/announcements', data);
};

const updateAnnouncement = (id: number, data: any) => {
  return http.put(`/announcements/${id}`, data);
};

const deleteAnnouncement = (id: number) => {
  return http.delete(`/announcements/${id}`);
};

const AnnouncementService = {
  getAllAnnouncements,
  createAnnouncement,
  updateAnnouncement,
  deleteAnnouncement,
};

export default AnnouncementService;